import axios from "./index";
import router from "../../router";

export default {
  async getList({ menu, type }) {
    return await axios
      .get("/getList", {
        params: {
          menu,
          type,
        },
      })
      .then((res) => res.data);
    /**
                                                                      menu : 게시판 메뉴명
                                                                      division: 게시판 유형 - 일반/이미지
                                                                    */
  },
  async saveList(boardData) {
    return await axios.post("/saveList", boardData).then((res) => res.data);
  },
  async getDetail(list_pk) {
    return await axios
      .get("/getListDetail", {
        params: {
          list_pk,
        },
      })
      .then((res) => res.data)
      .catch(() => router.push("/404"));
  },
  downLoadFile(file) {
    axios
      .get(file.path + file.save_name)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
        const a = document.createElement("a");
        a.href = url;
        a.download = file.original_name; // 원하는 이름으로 파일명 지정
        document.body.appendChild(a);
        a.click(); // 자동으로 눌러버리기
        setTimeout(() => {
          window.URL.revokeObjectURL(url); // 해당 url을 더 사용 못하게 날려버린다
        }, 60000);
        a.remove(); // a를 다 사용했으니 지워준다
      });
  },
};

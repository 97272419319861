<template>
  <div class="earning_image_item">
    <div class="flex items-center justify-center relative">
      <div class="image_wrapper">
        <img :src="imgSrc" alt="" />
      </div>
      <div class="overlay">
        <p class="text-white text-xl lg:text-2xl text-center">
          {{ title }}
        </p>
        <p class="text-white text-center">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    title: String,
    description: String,
  },
};
</script>

<style scoped>
.earning_image_item {
  float: left;
  position: relative;
  width: 33%;
  padding: 0 1rem;
  max-height: 280px;
  min-height: 280px;
  overflow: hidden;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .earning_image_item {
    width: 50%;
  }
}
@media (max-width: 496px) {
  .earning_image_item {
    width: 100%;
    padding: 0;
  }
  .image_wrapper {
    width: 100%;
    padding: 0;
    /* height: 280px; */
    overflow: hidden;
  }
}

.image_wrapper img {
  width: 100%;
  vertical-align: middle;
  min-height: 280px;
  object-fit: inherit;
}
.earning_image_item:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: white;
  background: rgb(3 61 132 / 70%);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 280px;
}
</style>

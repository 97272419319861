import { createStore } from "vuex";
import { menus } from "../constants/menus";

export default createStore({
  state: {
    sidebar: false,
    menus: menus,
  },
  mutations: {
    setSidebar(state, isOpen) {
      state.sidebar = isOpen;
    },
  },
  actions: {},
  getters: {
    menus(state) {
      return state.menus;
    },
    sidebar(state) {
      return state.sidebar;
    },
  },
  modules: {},
});

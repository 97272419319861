<template>
  <div class="menu_btn_wrapper">
    <button @click="onClickOpenMenu">
      <fa-icon :icon="['fas', 'outdent']" />
    </button>
  </div>
  <div class="logo_wrapper">
    <router-link to="/">
      <img
        :src="require('../../assets/m_logo.svg')"
        alt="이음_모바일로고"
        style="height: 2rem"
      />
    </router-link>
  </div>
  <div class="menu_btn_wrapper">
    <button>
      <fa-icon :icon="['fas', 'envelope-open']" />
    </button>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const navMenus = [
      {
        title: "About이음",
        children: ["인사말", "사업영역", "CI소개", "연혁"],
      },
      { title: "사업소개", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "공법소개", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "주요실적", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "고객지원", children: ["인사말", "사업영역", "CI소개", "연혁"] },
    ];

    const store = useStore();
    const onClickOpenMenu = () => {
      store.commit("setSidebar", true);
    };

    return {
      navMenus,
      onClickOpenMenu,
    };
  },
};
</script>

<style scoped>
.logo_wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
}
.menu_btn_wrapper {
  padding: 0 1rem;
}
.menu_btn_wrapper button {
  border: none;
  background: none;
  cursor: pointer;
}

svg {
  width: 20px;
  height: 20px;
}
</style>

export const business1 = {
  title: "신축구조설계",
  outline: {
    title: "신축구조 설계 개요",
    content: `
      구조설계 전문가 집단으로 최고의 기술력을 바탕으로 최상의 구조설계 엔지니어링을 제공합니다.<br/>
      일반건축물 부터 초고층건물, 장스팬건물, 입체트러스,<br>
      현수구조, PEB, 기하비선형 및 재료비선형 해석까지<br>
      다양한 분야에 걸쳐 구조엔지니어링 경험과 노하우를<br>
      가지고 있습니다.
    `,
  },
  characteristic: {
    title: ["통합 구조 설계 시스템", "(Total Structural Design System)"],
    content: `
        구조설계 표준화 및 구조안전성에 대한<br />
        종합적인 지원시스템의 필요성이 대두<br /><br />
        초기 단계에서부터 최종 단계까지 모든<br />구조관련 업무를 일괄로
        처리하는 시스템을 적용<br /><br />
        합리적이고 경제저긴 구조업무 수행
        `,
  },
  flows: [
    {
      title: "구조계획",
      content: `
          초기 건축 계획시 참여<br />시공성/경제성/<br />안정성 고려
        `,
    },
    {
      title: "구조설계",
      content: `
        구조설계 표준화 관리<br />최적의 구조물량<br />하자요인의 사전제거
      `,
    },
    {
      title: "구조도면 작성",
      content: `
      구조설계의 정확한 반영<br>시공성 사전 검토 가능<br>필요시 설계 피드백
    `,
    },
    {
      title: "현장지원",
      content: `
    필요시 신속한 도면변경<br>현장 지원 ENGINEERING
  `,
    },
  ],
};
export const business2 = {
  title: "내진성능평가",
  outline: {
    title: "내진성능평가 개요",
    content: `
          다수의 건물에 내진성능평가 및<br>
          내진보강설계를 진행하였습니다.<br>
          관공서 및 초/중/고등학교 그리고 대학교를 비롯해<br>
          특수구조물(토목구조물) 까지 수많은 내진성능평가를<br>
          성공적으로 수행하였습니다.<br>
          <br>
          기존 구조물에 대한 현장조사 부터 개별 부재의 유효강성을 고려하여 국내외 기준에 맞는 정확한 내진성능을 평가해 드립니다.
        `,
  },
  characteristic: {
    title: [
      "지진화산재해대책법 제'14조~16조'",
      "국토교통부 공고 제2014-1308호",
      "(건축물의 구조기준 등에 관한 규칙 일부개정령 입법예고)",
      "시설물의 안전관리에 관한 특별법 제 7조",
    ],
    content: `
        <u>1. 준공인가나 사용승인을 받은 후 20년이 지난 시설물중</u>
        <u>내진성능 평가를 받지 않은 시설물에 대해서는 내진성능평가를 해야한다.</u>
        <u>(시설물의 안전관리에 관한 특별법 제7조의2 제 1항)</u><br>
        <u>2. 내진특급 건축물에 해당하는 주요시설물-의무대상 건축물</u>
        <u>(1) 연면적500㎡ 이상인 위험물 저장 및 처리시설, 국가 또는</u>
        <u>지방자치단체의 청사,외국공관, 발전소, 전신전화국</u>
        <u>(2) 종합병원, 수술시설이나 응급시설이 있는 병원</u>
      `,
    wrapperStyle: "width:700px; height:500px;",
  },
  flows: [
    "현장 답사를 통한 구조물의 현황조사를 실시",
    "건물유지관리 담당자로부터 준공도서 수령과 하자현황 정취",
    "2항의 관련도서를 바탕으로  청취내용을 포함한 각 부재의 균열 및 누수, 보수, 보강 현황을 파악",
    "관련도서를 바탕으로 주요 구조 부재의 위치 및 대상 부재의 단면치수를 측정",
    "비파괴 장치로 구조부재의 콘크리트 압축 강도를 조사한다",
    "주요 구조부재의 콘크리트 압축강도를 조사한다",
    "페놀프틸레인 용액을 이용하여 콘크리트의 중성화 깊이를 조사한다(필요시)",
    "데오도라이트를 이용하여 건물의 수평 변위를 조사한다",
    "위 조사결과를 바탕으로 본 건물에 단계별 내진성능 평가를 실시하여 필요시 보수*보강 안을 제시하며, 현 건물의 요구 성능을 만족하도록 한다",
  ],
  level: [
    {
      title: "즉시거주",
      content: `지진 후 구조물의 피해는 경미하여 수직하중저항시스템과 지진력저항
            시스템은 지짖넞의 강성과 강도를 보유하고 있다.구조부재의
            손상으로 인명에 피해를 입을 가능성은 매우 낮으며 손상 부재에
            대한 보수가 필요하지만 즉시거주를 위해 반드시 팔요하지는 않다.
            `,
    },
    {
      title: "인명안전",
      content: `구조부재에 상당한 손상이 발생하여 횡강성과 강도의 손실이 있으나
            붕괴에 대해서는 여전히 여력을 보유하고 있다.구조부내에
            영구변형이 있으며 지진력저항시스템의 일부 요소에서 균열,
            파단,
            항복,
            혹은 좌굴이 발생할 수 있으나 구조부재의 손상으로
            인명손실의 위험은 낮다.구조부재의 보수는 가능하지만 경제저깅지
            않을수도 있다.당장 무너지지는 않으나 거주를 위해서는 보수와
            보강이 요구된다.`,
    },
    {
      title: "붕괴방지",
      content: `구조물이 심각한 피해를 입은 상태로 국부적 혹은 전체적인 붕괴가
            임박한 상태이다.지진력저항시스템에 상당한 강도 및 강성의 저하가
            있으며 횡방향 영구변형이 있다.그러나 중력하중저항시스템은
            여전히 사중을 시시할 수 있다.구조부재의 박락 등으로 인병피해가
            생길 수 있으며 일반적인 보수보강 후에도 거주에 안전하지 않을 수
            있다.여진으로 인해 붕괴가 발생 할 수 있다.
            `,
    },
  ],
  goal: [
    {
      1: "즉시거주",
      2: "거주가능",
      3: "기능수행",
    },
    {
      1: "즉시복구",
      2: "거주가능",
      3: "위치유지",
    },
    {
      1: "인명보호",
      2: "인명안전",
      3: "인명안전",
    },
    {
      1: "붕괴방지",
      2: "붕괴방지",
      3: "",
    },
  ],
  goal2: [
    {
      level: "특",
      cycle: "2400년",
      performance: "인명보호",
      detail: "기본설계지진x중요도개수",
    },
    {
      cycle: "1000년",
      performance: "기능수행",
      detail: "",
    },
    {
      level: "i",
      cycle: "2400년",
      performance: "붕괴방지",
      detail: "기본설계지진x중요도개수",
    },
    {
      cycle: "1000년",
      performance: "인명보호",
      detail: "기본설계지진x중요도계수",
    },
    {
      level: "ii",
      cycle: "2400년",
      performance: "붕괴방지",
      detail: "",
    },
    {
      cycle: "1000년",
      performance: "인명보호",
      detail: "기본설계지진x중요도계수",
    },
  ],
};

export const business3 = {
  title: "구조물안전진단",
  outline: {
    title: "구조물안전진단 개요",
    content: `
          <u style="text-decoration: none">우수한 전문가와 최신진단장비를 활용하여 취약시설에 대한</u>
          <u style="text-decoration: none">철저한 진단 및 문제원인에 대한 적절한 보강대안을 제시하는</u>
          <u style="text-decoration: none"> 국내 최고의 안전진단 구조전문가 집단입니다.</u>
          <u style="text-decoration: none">건설공사 안전점검 및 공동주택 재건축 판정 안전진단, 공동주택 하자판정 안전진단을 수행합니다.</u >
       `,
  },
  characteristic: {
    title: [],
    content: `
          <u>시설물의 안전관리에 관한 특별법에 의한 안전전검(기존건축물)</u><br>
          <u>*1층 및 2층 시설물 안전전검(시설물의 안전관리에 관한 특별법,</u>
          <u>시행령 제2조, 제6조, 제9조, 제 10조 관련)</u>
          <u>정기점검,최점검,정밀점검,긴급점검</u><br>
          <u>*1층 시설물 정밀안전진단(시설물의 안전관리에 관한 특별법 시행령 제9조 관련)</u>
          <u>건설기술 관리법에 의한 안전점검(건설현장)/(건설기술관리법 시행령 제46조의2, 제46조의 4관련)</u><br>
          <u>*정기안전점검(1차,2차,3차)</u><br>
          <u>*장기 공자웅에 따른 정밀안전진단</u><br>
          <u>재난관리법에 의한 안전점검(재난관리법 제18조, 23조, 시행령 제25조 관련)</u>
          <u>-재난위험시설 및 재난위험지역, 중점관리대상 시설 및 중점관리대상 지역 정기점검,수시점검</u>
      `,
    wrapperStyle: "width:800px; height:500px;",
  },
  flows: [
    {
      title: "정밀 안전 진단의 필요 여부 판단",
    },
    {
      title: "정밀 안전진단 실시",
    },
    {
      title: "평가와 분석",
    },
    {
      title: "보수*보강설계",
    },
    {
      title: "보수*보강시공",
    },
    {
      title: "사후관리시스템",
    },
  ],
};
export const business4 = {
  title: "내진보강공사",
  outline: {
    title: "내진보강공사 개요",
    content: `
          비선형해석 및 유한요소해석을 통해 구조물의 변위를<br>
          저감시키는 최신 진동제어 기술을 엔지니어링 합니다.<br>
          <br>
          변위의존형과 속도의존형 제진장치(Damping Device)를<br>
          적재적소에 설치하여 지진에너지를 소산시키고 진동 발생후 제진장치만 교체하면<br>
          전체 구조물에 대한 보수.보강없이 최고의 구조물 상태를 유지할수 있습니다.
        `,
  },
  flows: [
    "현장 답사를 통한 구조물의 현황조사를 실시",
    "건물유지관리 담당자로부터 준공도서 수령과 하자현황 정취",
    "2항의 관련도서를 바탕으로  청취내용을 포함한 각 부재의 균열 및 누수, 보수, 보강 현황을 파악",
    "관련도서를 바탕으로 주요 구조 부재의 위치 및 대상 부재의 단면치수를 측정",
    "비파괴 장치로 구조부재의 콘크리트 압축 강도를 조사한다",
    "주요 구조부재의 콘크리트 압축강도를 조사한다",
    "페놀프틸레인 용액을 이용하여 콘크리트의 중성화 깊이를 조사한다(필요시)",
    "데오도라이트를 이용하여 건물의 수평 변위를 조사한다",
    "위 조사결과를 바탕으로 본 건물에 단계별 내진성능 평가를 실시하여 필요시 보수*보강 안을 제시하며, 현 건물의 요구 성능을 만족하도록 한다",
  ],
};
export const business5 = {
  title: "공법개발",
  outline: {
    title: "공법개발  개요",
    content: `
        국내 유수의 대학교와 연구소, 그리고 (주)이음건설산업이 산학연 협력하여<br>
        내진 및 제진에 특화된 새롭고 우수한 공법을 끊임없이 연구/개발하고 있으며,<br>
        개발된 새로운 공법은 특허 및 성능인증을 취득하여<br>
        설계 및 현장에 바로 적용, 사용되고 있습니다.<br>
    `,
  },
};

export const categories = [
  "전체",
  "카다로그",
  "CI",
  "시방서 및 특허",
  "사업자등록증",
  "기타",
];
export const boardList = [
  {
    notice_yn: true,
    download_yn: false,
    category: "카다로그",
    title: "TMD 공법에 대해 소개 드립니다. ",
    writer: "이음",
    view: 2121,
    datetime: "2021-11-29",
  },
  {
    notice_yn: true,
    download_yn: false,
    category: "CI",
    title: "CI 공지입니다.",
    writer: "이음",
    view: 891,
    datetime: "2021-09-11",
  },
  {
    notice_yn: false,
    download_yn: true,
    category: "카다로그",
    title: "C-TMD 카다로그 210815",
    writer: "이음",
    view: 482,
    datetime: "2021-08-15",
  },
  {
    notice_yn: false,
    download_yn: true,
    category: "시방서 및 특허",
    title: "NB브레이스 내진보강장치-특별시방서",
    writer: "이음",
    view: 279,
    datetime: "2021-08-11",
  },
  {
    notice_yn: false,
    download_yn: true,
    category: "사업자등록증",
    title: "이음건설산업 사업자등록증",
    writer: "이음",
    view: 452,
    datetime: "2021-07-12",
  },
];

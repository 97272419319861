<template>
  <li class="business_area_item">
    <router-link :to="`/board/사업소개/${title}`" class="link">
      <img
        :src="require(`../../assets/images/main/business${index}.jpg`)"
        alt="사업분야사진"
      />
      <div class="area_summary">
        <span>{{ title }}</span>
        <button>+</button>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    index: Number,
    title: String,
  },
};
</script>

<style scoped>
.business_area_item {
  /* height: 100%; */
  text-align: center;
}
.business_area_item .link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.business_area_item .area_summary {
  position: relative;
  z-index: 10;
  top: -6rem;
  padding: 1rem 0.5rem 0.8rem 0.5rem;
  border-radius: 10px;
  background: white;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0);
  transition: all 400ms ease;
}
.business_area_item .area_summary:hover {
  transform: translateY(-1rem);
}
.business_area_item .area_summary span {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.2rem;
}
.business_area_item .area_summary button {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  border-radius: 100%;
  border: none;
  color: #222;
  background: #ddd;
  transition: all 200ms linear;
  font-size: 1.7rem;
}
.business_area_item .area_summary:hover button {
  color: white;
  background: #222;
}
@media (max-width: 768px) {
  img {
    width: 100%;
  }
}
</style>

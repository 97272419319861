<template>
  <div class="board_content_section">
    <form @submit="onSubmit" class="form">
      <textarea
        name=""
        placeholder="개인정보처리방침안내..."
        id=""
        cols="30"
        rows="10"
      ></textarea>
      <div class="flex items-center gap-2 justify-end">
        <p>개인정보처리방침안내의 내용에 동의합니다.</p>
        <input type="checkbox" v-model="agreement" />
      </div>
      <div class="flex flex-col py-2">
        <label for="name">성명 *</label>
        <input
          v-model="name"
          type="text"
          id="name"
          class="form_input"
          :class="nameError ? 'error' : ''"
          placeholder="이음"
        />
        <span style="color: red" class="pt-1">{{ nameError }}</span>
      </div>
      <div class="flex flex-col py-2">
        <label for="phone">연락처 *</label>
        <input
          v-model="contact"
          type="phone"
          max="11"
          id="phone"
          class="form_input"
          :class="contactError ? 'error' : ''"
          placeholder="01012345678"
        />
        <span style="color: red" class="pt-1">{{ contactError }}</span>
      </div>
      <div class="flex flex-col py-2">
        <label for="email">이메일 *</label>
        <input
          v-model="email"
          type="email"
          id="email"
          class="form_input"
          :class="emailError ? 'error' : ''"
          placeholder="eumcon@eum.com"
        />
        <span style="color: red" class="pt-1">{{ emailError }}</span>
      </div>
      <div class="flex flex-col py-2">
        <label for="content">내용 *</label>
        <textarea
          placeholder="10자 이상 입력해주세요"
          v-model="content"
          type="text"
          id="content"
          class="form_input"
          :class="contentError ? 'error' : ''"
        />
      </div>
      <div class="flex justify-center">
        <button class="btn_submit">등록하기</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useField, useForm } from "vee-validate";

import * as yup from "yup";
import Post from "../../../utils/api/Post";
export default {
  setup() {
    const { handleSubmit, resetForm } = useForm();
    const { value: name, errorMessage: nameError } = useField(
      "name",
      yup.string().required("이름을 입력해주세요")
    );
    const { value: contact, errorMessage: contactError } = useField(
      "contact",
      yup
        .string()
        .required("연락처를 입력해주세요")
        .length(11 || 10, "'-' 없이 숫자만 입력해주세요")
        .min(10)
        .max(11)
    );
    const { value: email, errorMessage: emailError } = useField(
      "email",
      yup
        .string()
        .email("이메일 형식을 확인해주세요")
        .required("이메일을 입력해주세요.")
    );
    const { value: content, errorMessage: contentError } = useField(
      "content",
      yup.string().required().min(10, "10자 이상 입력해주세요")
    );

    const agreement = ref(false);

    const onSubmit = handleSubmit(async (values) => {
      console.log(values);
      await Post.saveQustion({
        menu: "eum",
        title: content.value,
        contact: contact.value,
        email: email.value,
        agree: agreement.value,
      });
      resetForm();
    });
    return {
      onSubmit,
      name,
      contact,
      email,
      content,
      agreement,
      nameError,
      contactError,
      emailError,
      contentError,
    };
  },
  components: {},
};
</script>

<style scoped>
.form {
  max-width: 780px;
  margin: 0 auto;
}
.form textarea {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  height: 150px;
  border: 1px solid #e9e9e9;
  background: #f7f7f7;
}
.form .form_input {
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428;
  background: #fff;
  border: 1px solid #ccc;
}
.form .form_input input {
  height: 34px;
}
.form .form_input:focus {
  box-shadow: none;
}
.form .btn_submit {
  color: #fff;
  background-color: #428bca;
  padding: 10px 25px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
}
.form .form_input.error {
  border: 1px solid red;
}
</style>

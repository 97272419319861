<template>
  <div
    style="margin-top: -6rem; position: relative"
    class="flex items-center justify-center"
  >
    <ul style="max-width: 1100px; margin: 0 auto">
      <li
        class="circle_wrapper wrapper_mid"
        v-for="(item, idx) in flows"
        :key="item"
      >
        <div class="circle circle_mid">
          <p class="font-extra-bold title" v-html="item.title"></p>
          <p class="font-medium text-md text-center" v-html="item.content"></p>
        </div>
        <div class="next_arrow" v-if="idx !== flows.length - 1"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    flows: Array,
  },
};
</script>
<style scoped>
.circle_wrapper {
  float: left;
  margin-left: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.wrapper_mid {
  width: 259px;
  height: 260px;
}

.circle {
  position: absolute;
  top: 50%;

  background: white;
  z-index: 10;
  border: 2px solid #003b8f;
  border-radius: 100%;
  color: #1c1c1c;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.circle_mid {
  width: 232px;
  height: 230px;
  transform: translateY(-100px);
  padding: 0 31px;
}
.circle_mid .title {
  font-size: 1.1rem;
  padding: 0.8rem;
}
.wrapper_mid .next_arrow {
  position: absolute;
  top: 50%;
  right: -1.1rem;
  width: 20px;
  height: 20px;

  background: url("../../../assets/images/icon/arrow.png") no-repeat;
}
@media (max-width: 1115px) {
  .wrapper_mid {
    width: 100%;
  }
  .circle_wrapper {
    margin: 0;
    height: 260px;
    width: 259px;
  }
  .wrapper_mid .next_arrow {
    position: absolute;
    top: calc(100% + 1rem);
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background: url("../../../assets/images/icon/arrow.png") no-repeat;
    transform: rotate(90deg);
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>

<style></style>

<template>
  <div class="timeline_block">
    <div class="timeline_icon"></div>
    <div class="timeline_content">
      <p class="font-black text-4xl text-black mb-6">{{ year }}</p>
      <ul>
        <li v-for="item in history" :key="item">
          <p class="text-lg">
            <span class="font-extra-bold">{{ item.month }}</span
            >{{ item.content }}
          </p>
        </li>
        <li v-if="image">
          <img
            :src="image"
            :alt="`${year}년 연혁사진`"
            class="w-full h-full mt-8"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    year: Number,
    history: Array,
    image: String,
  },
};
</script>

<style scoped>
.timeline_icon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  margin-left: -10px;
  margin-top: 4px;
  border: 3px solid #0f52a1;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
}

.timeline_block:nth-of-type(2n + 1) .timeline_content {
  text-align: right;
}
.timeline_block:nth-of-type(2n) .timeline_content {
  left: 54%;
}

.timeline_content {
  position: relative;
  width: 46%;
  padding-bottom: 50px;
  line-height: 20px;
}
</style>

const menus = [
  {
    title: "About이음",
    link: `/board/About이음/인사말`,
    submenus: [
      { component: "greeting", title: "인사말" },
      { component: "business", title: "사업영역" },
      { component: "ci", title: "CI소개" },
      { component: "history", title: "연혁" },
    ],
  },
  {
    title: "사업소개",
    link: "/board/사업소개/신축구조설계",
    submenus: [
      { component: "business1", title: "신축구조설계" },
      { component: "business2", title: "내진성능평가" },
      { component: "business3", title: "구조물 안전진단" },
      { component: "business4", title: "내진보강공사" },
      { component: "business5", title: "공법개발" },
    ],
  },
  {
    title: "공법소개",
    link: "/board/공법소개/C-TMD",
    submenus: [
      { component: "method1", title: "C-TMD" },
      { component: "method2", title: "NB브레이스" },
    ],
  },
  {
    title: "주요실적",
    link: "/board/주요실적/표",
    submenus: [
      { component: "earning1", title: "표" },
      { component: "earning2", title: "사진" },
    ],
  },
  {
    title: "고객지원",
    link: "/board/고객지원/자료실",
    submenus: [
      { component: "files", title: "자료실" },
      { component: "qna", title: "Q&A" },
      { component: "employing", title: "채용정보" },
      { component: "map", title: "찾아오시는길" },
    ],
  },
];

export { menus };

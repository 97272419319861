<template>
  <div
    class="w-full mx-auto flex flex-col gap-4 py-16 items-center"
    style="background-color: #f7f7f7; padding-top: 80px"
  >
    <div class="w-full py-4">
      <div class="mx-auto mb-3 h-0.5 w-10 bg-primary-blue"></div>
      <h3 class="text-3xl text-center">{{ year }}</h3>
    </div>
    <table class="py-10 earning_table">
      <colgroup>
        <col style="width: 45%" />
        <col style="width: 18%" />
        <col style="width: 25%" />
        <col style="width: 12%" />
      </colgroup>
      <thead>
        <tr>
          <th>프로젝트명</th>
          <th>공사내용(개소)</th>
          <th>진행년월</th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in earningItems" :key="item">
          <td>{{ item.title }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    year: String,
    earningItems: Array,
  },
};
</script>

<style scoped>
.earning_table {
  max-width: 1200px;
  width: 100%;
}
.earning_table th,
.earning_table td {
  border: 1px solid #ddd;
  text-align: center;
}
.earning_table th {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background: #333;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
}
.earning_table tbody td {
  padding: 20px;
  background: white;
  font-size: 15px;
  letter-spacing: -0.5px;
  color: #666;
  font-weight: 300;
}
.earning_table tbody td:first-child {
  font-weight: 400;
  color: #333;
  background: #fbfbfb;
}
</style>

<template>
  <div
    ref="containerRef"
    class="building_method text-white bg-gray-800 h-80 cursor-pointer"
  >
    <p class="title text-white text-center pt-10">{{ title }}</p>
    <strong class="description">
      <p class="text-white text-center" v-for="desc in description" :key="desc">
        {{ desc }}
      </p>
    </strong>
    <button
      class="link_btn text-sm py-4 px-12 my-8 mx-auto border border-white transition-all duration-300"
      @click="$router.push(link)"
    >
      자세히보기
    </button>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: {
    bgImage: String,
    title: String,
    description: Array,
    link: String,
  },
  setup(props) {
    const containerRef = ref();
    onMounted(() => {
      containerRef.value.style.background = `
        #2c3137 url(${props.bgImage}) no-repeat
      `;
    });
    return {
      containerRef,
    };
  },
};
</script>

<style scoped>
.building_method {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
  font-size: 0.8rem;
  font-weight: 200;
  height: 350px;
}
.building_method:hover .link_btn {
  background: white;
  color: black;
}
@media (max-width: 768px) {
  .building_method {
    width: 100%;
    font-size: 0.7rem;
    justify-content: center;
  }
}
.building_method .title {
  font-size: 1rem;
  width: 100%;
  letter-spacing: 0.2em;
}
.building_method .title::after {
  display: block;
  content: "";
  width: 3%;
  height: 1px;
  margin: 15px auto;
  background: #eee;
}
.building_method .description {
  display: block;
  color: #fff;
  font-size: 1.8em;
  font-weight: 200;
  line-height: 1.2em;
  font-weight: 200;
  padding: 10px 15px;
}
</style>

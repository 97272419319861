<template>
  <div id="sidebar" :style="sidebarStyle">
    <div class="sidebar_title_wrapper">
      <span class="sidebar_title">이음건설산업</span>
      <div class="sidebar_close_btn" @click="onClickClose">
        <fa-icon :icon="['fas', 'xmark']" />
      </div>
    </div>
    <div class="divider"></div>
    <div class="sidebar_menu_wrapper">
      <ul class="sidebar_menu">
        <li
          v-for="(menu, idx) in menus"
          :key="menu.title"
          class="sidebar_menu_title"
        >
          <div class="sidebar_menu_item" @click="() => showSubmenu(menu, idx)">
            <span>{{ menu.title }}</span>
            <fa-icon :icon="['fas', 'angle-down']" />
          </div>
          <ul
            class="submenu"
            :style="
              idx === openedMenu
                ? `height:calc(${menu.submenus.length} * 35px)`
                : ''
            "
          >
            <li
              v-for="submenu in menu.submenus"
              :key="submenu.title"
              :class="idx === openedMenu ? 'on' : ''"
              @click="goMenu(menu.title, submenu.title)"
            >
              <span> - {{ submenu.title }} </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="backgrond" v-if="isSidebarOpen" @click="onClickClose"></div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const isSidebarOpen = computed(() => store.getters["sidebar"]);

    const sidebarStyle = computed(() =>
      isSidebarOpen.value
        ? "transform:translateX(0)"
        : "transform:translateX(-300px)"
    );

    const onClickClose = () => {
      store.commit("setSidebar", false);
    };
    return {
      onClickClose,
      isSidebarOpen,
      sidebarStyle,
      ...useMenus(),
    };
  },
};
const useMenus = () => {
  const router = useRouter();
  // const route = useRoute();
  const store = useStore();
  const menus = computed(() => store.getters["menus"]);

  const openedMenu = ref(null);
  const showSubmenu = (menu, idx) => {
    console.log(menu, idx);
    if (openedMenu.value === idx) {
      openedMenu.value = null;
      return;
    }
    openedMenu.value = idx;
  };
  const goMenu = (category, subcategory) => {
    store.commit("setSidebar", false);
    router.push({
      name: "BoardContent",
      params: {
        category,
        subcategory: subcategory ?? null,
      },
    });
  };
  return {
    menus,
    openedMenu,
    showSubmenu,
    goMenu,
  };
};
</script>

<style scoped>
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 249px;
  height: 100%;
  background: white;
  z-index: 10000;
  transition: all 500ms ease-in-out;
}
.backgrond {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.sidebar_title_wrapper {
  width: 100%;
  height: 60px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar_title {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
}
.sidebar_title_wrapper .sidebar_close_btn {
  position: absolute;
  right: 0.5rem;
  border: none;
  background: white;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.sidebar_title_wrapper .sidebar_close_btn svg {
  width: 100%;
  height: 100%;
}

.divider {
  background-color: #666;
  text-align: center;
  padding: 10px 0;
}

.sidebar_menu {
  width: 100%;
}

.sidebar_menu li {
  display: inline;
  padding: 10px 0;
  text-align: center;
}
.sidebar_menu .sidebar_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #aaa;
  text-align: left;
  background: #fff;
  color: #333;
  cursor: pointer;
  position: relative;
}

.submenu {
  width: 100%;
  overflow: hidden;
  height: 0;
  transition: all 600ms ease-in-out;
  transform-origin: top;
}
.submenu li {
  width: 100%;
  display: block;
  border-bottom: 1px solid #eee;
  padding: 5px 20px;
  height: 35px;
  text-align: left;
  cursor: pointer;
}
</style>

<template>
  <div>
    <div class="w-full flex justify-between p-2 my-2 items-center">
      <span class="text_info">
        Total {{ numberFormat(normal.length + notices.length) }}건 {{ 1 }}페이지
      </span>
      <button class="btn_search" @click="setModal(true)">
        <fa-icon :icon="['fas', 'search']"></fa-icon>
      </button>
    </div>
    <div class="table_wrap">
      <table class="tbl">
        <caption>
          자료실 목록
        </caption>
        <thead class="tbl_head">
          <tr>
            <th scope="col">번호</th>
            <th scope="col">제목</th>
            <th scope="col" class="m_hide">다운로드</th>
            <th scope="col" class="m_hide">글쓴이</th>
            <th scope="col" class="m_hide">조회</th>
            <th scope="col" class="m_hide">날짜</th>
          </tr>
        </thead>
        <tbody class="tbl_body">
          <tr class="notice" v-for="notice in notices" :key="notice">
            <td class="td_idx">공지</td>
            <td class="td_subject">
              <div class="font-bold">
                <span class="category">
                  {{ notice.division }}
                </span>
                <span
                  class="inline-block cursor-pointer"
                  @click="onClickTitle(notice.list_pk)"
                >
                  {{ notice.title }}
                </span>
                <span v-if="notice.comment_count > 0" class="cnt_comment">{{
                  `+${notice.comment_count}`
                }}</span>
              </div>
              <div class="m_view mt-2">
                <div>
                  <span calss="">{{ notice.writer }}</span>
                </div>
                <div>
                  <span class="inline-block w-24"
                    >Hit {{ numberFormat(notice.hit) }}</span
                  >
                  <span>{{ notice.enrollment_date.slice(0, 10) }}</span>
                </div>
              </div>
            </td>
            <td class="td_name m_hide">
              <button v-if="notice.file.length > 0">다운로드</button>
            </td>
            <td class="td_name m_hide">{{ notice.writer }}</td>
            <td class="td_num m_hide">{{ numberFormat(notice.hit) }}</td>
            <td class="td_datetime m_hide">
              {{ notice.enrollment_date.slice(0, 10) }}
            </td>
          </tr>
          <tr v-for="(item, idx) in normal" :key="item">
            <td class="td_idx">{{ normal.length - idx }}</td>
            <td class="td_subject">
              <div class="font-bold">
                <span class="category">
                  {{ item.division }}
                </span>
                <span
                  class="inline-block cursor-pointer"
                  @click="onClickTitle(item.list_pk)"
                >
                  {{ item.title }}
                </span>
                <span v-if="item.comment_count > 0" class="cnt_comment">
                  {{ `+${item.comment_count}` }}
                </span>
              </div>
              <div class="m_view mt-2">
                <div>
                  <span calss="">{{ item.writer }}</span>
                </div>
                <div>
                  <span class="inline-block w-24"
                    >Hit {{ numberFormat(item.hit) }}</span
                  >
                  <span> Date {{ item.enrollment_date.slice(0, 10) }}</span>
                </div>
              </div>
            </td>
            <td class="td_name m_hide">
              <button
                class="btn_download"
                v-if="item.file.length > 0"
                @click="onClickDownload(item.file)"
              >
                다운로드
              </button>
            </td>
            <td class="td_name m_hide">{{ item.writer }}</td>
            <td class="td_num m_hide">{{ numberFormat(item.hit) }}</td>
            <td class="td_datetime m_hide">
              {{ item.enrollment_date.slice(0, 10) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="fade">
      <div v-show="searchModal" class="bg_modal" @click="setModal(false)">
        <SearchModal
          @close-modal="setModal(false)"
          @search-keyword="onSearch"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import SearchModal from "./SearchModal.vue";
import { useRoute, useRouter } from "vue-router";
// import Board from "../utils/api/Board";

import { numberFormat } from "../utils/numberFormat";

export default {
  props: {
    currentTab: String,
    notices: Array,
    normal: Array,
  },
  components: { SearchModal },
  setup() {
    const searchModal = ref(false);
    const setModal = (bool) => (searchModal.value = bool);
    const router = useRouter();
    const route = useRoute();
    const onClickTitle = (postId) => {
      router.push({
        name: "Post",
        params: { ...route.params, postId: postId },
      });
    };
    const { onSearchKeyword } = useSearch();

    const onSearch = async (value) => {
      const result = await onSearchKeyword(value);
      console.log(result);
    };

    const onClickDownload = (files) => {
      files.forEach((file) =>
        window.open(file.path + file.save_name, "_blank")
      );
      // Promise.allSettled(files.map((file) => Board.downLoadFile(file)));
    };
    return {
      searchModal,
      setModal,
      onClickTitle,
      onSearch,
      onClickDownload,
      numberFormat,
    };
  },
};
const useSearch = () => {
  const onSearchKeyword = async (value) => {
    console.log("검색어", value);
    const result = [
      {
        notice_yn: true,
        download_yn: false,
        category: "CI",
        title: "CI 공지입니다.",
        writer: "이음",
        view: 891,
        datetime: "2021-09-11",
      },
      {
        notice_yn: false,
        download_yn: true,
        category: "카다로그",
        title: "C-TMD 카다로그 210815",
        writer: "이음",
        view: 482,
        datetime: "2021-08-15",
      },
    ];
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(result);
      }, 1000);
    });
  };

  return {
    onSearchKeyword,
  };
};
</script>

<style scoped>
.text_info {
  font-size: 0.92rem;
  color: #4e546f;
}
.btn_search {
  color: #bababa;
}
.btn_search svg {
  width: 1.25rem;
  height: 1.25rem;
}
.btn_search:hover {
  color: black;
}
.table_wrap .tbl {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 5px;
  background: #fff;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.table_wrap caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.table_wrap .tbl .tbl_head th {
  border-top: 1px solid black;
  border-bottom: 1px solid #ddd;

  padding: 20px 0;
  font-weight: normal;
  text-align: center;
  height: 40px;
}
.table_wrap .tbl tr:hover {
  background: #fcfcfc;
}
.table_wrap .tbl td {
  text-align: center;
  color: #666;
  padding: 10px 5px;
  border-top: 1px solid #ecf0f1;
  border-bottom: 1px solid #ecf0f1;
  line-height: 1.4em;
  height: 60px;
  word-break: break-all;
}
.btn_download {
  border: 0px;
  background-color: #333;
  padding: 3px 12px 3px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}
.table_wrap .tbl .tbl_body .notice {
  border-left: 2px solid transparent;
}
.table_wrap .tbl .tbl_body .notice td {
  background: #fcfcfc !important;
  border-bottom: 1px solid #f8e6ee;
}
.table_wrap .tbl .tbl_body .notice .td_idx {
  font-weight: bold;
  color: #c42828;
  font-size: 1.1em;
  height: 100%;
}
.table_wrap .tbl .tbl_body .td_idx,
.table_wrap .tbl .tbl_body .td_num {
  width: 50px;
  text-align: center;
}
.table_wrap .tbl .tbl_body .td_subject {
  text-align: left;
}
.table_wrap .tbl .tbl_body .td_subject .cnt_comment {
  background: #fff;
  color: #333;
  font-size: 0.6rem;
  height: 15px;
  line-height: 15px;
  vertical-align: middle;
  padding: 1px 5px;
  border-radius: 3px;
  border: 1px solid #aaa;
  margin-left: 0.6rem;
}
.table_wrap .tbl .tbl_body .td_subject .m_view {
  display: none;
}
.table_wrap .tbl .tbl_body .td_subject .category {
  display: inline-block;
  margin-right: 10px;
  background: #666;
  color: #fff;
  font-weight: normal !important;
  padding: 0px 8px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 0.95em;
  cursor: pointer;
}
.table_wrap .tbl .tbl_body .td_name {
  width: 90px;
  padding: 10px 0;
}
.table_wrap .tbl .tbl_body .td_datetime {
  width: 100px;
  text-align: center;
}

@media (max-width: 1100px) {
  .table_wrap .tbl .m_hide {
    display: none;
  }
}
@media (max-width: 768px) {
  .table_wrap .tbl .tbl_body .td_subject .m_view {
    display: block;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Board from "../views/Board.vue";
import BoardContent from "../views/BoardContent.vue";
import Post from "../views/Post.vue";
import PostWrite from "../views/PostWrite.vue";
import Photo from "../views/Photo.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/board",
    name: "Board",
    component: Board,
    props: true,
    children: [
      {
        path: ":category/:subcategory?",
        name: "BoardContent",
        component: BoardContent,
        props: true,
      },
      {
        path: ":category/:subcategory/:postId",
        name: "Post",
        component: Post,
        props: true,
      },
      {
        path: ":category/:subcategory/write",
        name: "PostWrite",
        component: PostWrite,
        props: true,
      },
      {
        path: ":category/:subcategory/photo/:photoId",
        name: "Photo",
        component: Photo,
        props: true,
      },
    ],
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  // {
  //     path: "/about",
  //     name: "About",
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () =>
  //         import ( /* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

export const banners = [
  {
    pc: require("../assets/images/banners/banner1.jpg"),
    mobile: require("../assets/images/banners/banner1_mobile.jpg"),
  },
  {
    pc: require("../assets/images/banners/banner2.jpg"),
    mobile: require("../assets/images/banners/banner2_mobile.jpg"),
  },
  {
    pc: require("../assets/images/banners/banner3.jpg"),
    mobile: require("../assets/images/banners/banner3_mobile.jpg"),
  },
];

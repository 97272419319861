<template>
  <div class="w-full py-20 px-4 md:px-20" style="background: #f8f8f8">
    <Outline :headerTitle="' '" :title="sectionTitle" />
    <div
      class="w-full flex items-center justify-center"
      style="margin-top: -6rem; visiblility: hidden; opacity: 0"
      v-animate
      animation="animate__animated animate__fadeInUp"
    >
      <div class="item space-y-6" :style="wrapperStyle">
        <span>
          <h3
            class="font-bold text-xl md:text-2xl tracking-tighter md:tracking-tight"
            v-for="text in innerTitle"
            :key="text"
          >
            {{ text }}
          </h3>
        </span>
        <slot name="image"></slot>
        <p
          class="font-medium text-lg tracking-tighter md:tracking-tight"
          v-html="content"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
export default {
  components: { Outline },
  props: {
    sectionTitle: String,
    innerTitle: Array,
    content: String,
    wrapperStyle: String,
  },
  setup(props) {
    console.log(props.wrapperStyle);
  },
};
</script>

<style scoped>
.item {
  min-width: 350px;
  width: 500px;
  height: 400px;
  position: relative;
  margin: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background: white;
  color: black;
  border-top: 3px solid #0f52a1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 200ms linear;
  cursor: pointer;
}
.item:hover {
  background: #0f52a1;
}
.item:hover p,
.item:hover h3 {
  color: white;
}

:deep(.item p u) {
  text-decoration: none;
  display: block;
}
@media (max-width: 768px) {
  :deep(.item p u) {
    display: inline;
  }
}
</style>

<template>
  <section class="main_slider">
    <swiper v-bind="swiperOptions" class="banner_swiper">
      <swiper-slide v-for="(img, idx) in bannerImgs" :key="img + idx">
        <div class="backgroundimg">
          <div class="pc_only">
            <img :src="img.pc" style="width: 100vw" />
          </div>
          <div class="mobile_only">
            <img :src="img.mobile" />
          </div>
        </div>
        <div class="banner_text">
          <h2
            class="text-white pb-4 md:text-lg lg:text-xl"
            style="visiblility: hidden; opacity: 0"
            v-animate
            animation="animate__animated animate__fadeInUp"
          >
            최고의 내진보강기술로 지진재해로부터 안전하게 지키겠습니다.
          </h2>
          <h2
            class="banner_title"
            style="visiblility: hidden; opacity: 0"
            v-animate
            animation="animate__animated animate__fadeInUp"
          >
            |주|이음건설산업
          </h2>

          <button
            @click="$router.push('/board/About이음/인사말')"
            style="visiblility: hidden; opacity: 0"
            v-animate
            animation="animate__animated animate__fadeInUp"
          >
            <strong style="font-size: 16px; font-weight: 500">READ MORE</strong>
          </button>
        </div>
      </swiper-slide>

      <div class="swiper-pagination"></div>
    </swiper>
  </section>
</template>

<script>
import { banners } from "@/constants/banner";
import { ref } from "@vue/reactivity";
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const bannerImgs = [...banners];

    const currentSlide = ref(1);
    const maxSlides = bannerImgs.length;
    const onClickPrev = () => {
      if (currentSlide.value === 0) {
        currentSlide.value = maxSlides - 1;
        return;
      }
      currentSlide.value--;
    };
    const onClickNext = () => {
      if (currentSlide.value === maxSlides - 1) {
        currentSlide.value = 0;
        return;
      }
      currentSlide.value++;
    };
    return {
      bannerImgs,
      currentSlide,
      onClickNext,
      onClickPrev,
      ...useSwiper(),
    };
  },
};
const useSwiper = () => {
  const swiperOptions = ref({
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    }, // Disable preloading of all images

    pagination: {
      clickable: true,
    },

    navigation: true,
    slidesPerView: 1,
  });
  return { swiperOptions };
};
</script>

<style scoped>
.main_slider {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  transition: all 200ms linear;
  height: 100%;
}
.main_slider >>> swiper-container {
  min-height: 300px;
}

.main_slider >>> swiper-slide {
  height: auto;
  padding: 0;
  background-color: #000;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.main_slider >>> .banner_text {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
}

.main_slider >>> .swiper-button-next,
.main_slider >>> .swiper-button-prev,
.main_slider >>> .swiper-button-prev::after,
.main_slider >>> .swiper-button-next::after {
  margin-top: 1rem;
  color: #fff;
  opacity: 0;
  transition: all 150ms linear;
}
.main_slider:hover >>> .swiper-button-next,
.main_slider:hover >>> .swiper-button-prev,
.main_slider:hover >>> .swiper-button-prev::after,
.main_slider:hover >>> .swiper-button-next::after {
  opacity: 0.6;
}
.main_slider >>> .swiper-button-next:hover,
.main_slider >>> .swiper-button-prev:hover {
  opacity: 1;
}

.main_slider
  >>> .swiper-pagination.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background: white;
  opacity: 0.9;
}
.main_slider
  >>> .swiper-pagination.swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background: #c53428;
  opacity: 1;
}

.main_slider >>> .banner_text h2 {
  text-shadow: 0px 1px 4px #000;
  margin-bottom: 2rem;
  padding: 0;
}
.main_slider >>> .banner_text h2.banner_title {
  font-size: 3.2rem;
  color: white;
  line-height: 1.3rem;
  font-weight: 700;
}

.main_slider >>> .banner_text button {
  line-height: 20px;
  font-family: inherit;
  font-size: inherit;
  background: none;
  cursor: pointer;
  width: 18rem;
  height: 50px;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50px;
}

.main_slider >>> .banner_text button strong {
  transition: all 200ms linear;
  z-index: 3;
}
.main_slider >>> .banner_text button::after {
  z-index: -1;
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  background: #fff;
  opacity: 0;
  transition: all 200ms linear;
  border: 1px solid white;
}
.main_slider >>> .banner_text button:hover {
  color: #111;
}
.main_slider >>> .banner_text button:hover::after {
  border-radius: 50px;
  width: 100%;
  opacity: 1;
}

.main_slider .backgroundimg {
  z-index: 1;
  width: 100%;
  height: 100%;
}
.main_slider .backgroundimg .mobile_only {
  display: none;
}
@media (max-width: 1300px) {
  .main_slider >>> .banner_text h2 {
    font-size: 1rem;
  }
  .main_slider >>> .banner_text h2.banner_title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .main_slider >>> .banner_text button {
    width: 12rem;
  }
}
@media (max-width: 768px) {
  .main_slider .backgroundimg .mobile_only {
    display: block;
  }
  .main_slider .backgroundimg .pc_only {
    display: none;
  }
  .main_slider >>> .swiper-button-next,
  .main_slider >>> .swiper-button-prev,
  .main_slider
    >>> .swiper-button-prev::after
    .main_slider
    >>> .swiper-button-next::after {
    display: none;
  }
  .main_slider >>> .banner_text h2 {
    font-size: 0.8rem;
  }
  .main_slider >>> .banner_text h2.banner_title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 498px) {
  .main_slider >>> .banner_text {
    top: 40%;
  }

  .main_slider >>> .banner_text button {
    width: 9rem;
    height: 2.5rem;
  }
  .main_slider >>> .banner_text h2 {
    font-size: 0.8rem;
  }
  .main_slider >>> .banner_text h2.banner_title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
</style>

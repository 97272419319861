<template>
  <div id="nav" ref="navRef">
    <HeaderContent />
  </div>
  <div id="nav_mobile" ref="mobileNavRef">
    <HeaderContentMobile />
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import HeaderContent from "../layout/HeaderContent.vue";
import HeaderContentMobile from "../layout/HeaderContentMobile.vue";

import _ from "lodash";

export default {
  components: { HeaderContent, HeaderContentMobile },
  setup() {
    const navRef = ref(null);
    const mobileNavRef = ref(null);
    onMounted(() => {
      document.addEventListener(
        "scroll",
        _.throttle(() => {
          if (window.innerWidth > 1100) {
            if (window.scrollY >= 81) {
              navRef.value.style.position = "fixed";
              navRef.value.style.height = "60px";
            } else {
              navRef.value.style.position = "absolute";
              navRef.value.style.height = "81px";
            }
          } else {
            if (window.scrollY >= 81) {
              mobileNavRef.value.style.position = "fixed";
            } else {
              mobileNavRef.value.style.position = "absolute";
            }
          }
        }, 100)
      );
    });
    return {
      navRef,
      mobileNavRef,
    };
  },
};
</script>

<style scoped>
#nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  height: 81px;
  z-index: 10000;
  background: white;
  transition: all 300ms linear;
}

#nav_mobile {
  position: absolute;
  top: 0;
  left: 0;
  display: none;

  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
  z-index: 9999;

  min-width: 320px;
}
@media (max-width: 1100px) {
  #nav {
    display: none;
  }
  #nav_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<template>
  <div>
    <Outline
      :headerTitle="''"
      :title="outline.title"
      :content="outline.content"
    />
    <MethodCharacteristic
      :sectionTitle="characteristic.title"
      :content="characteristic.content"
    >
      <template v-slot:content>
        <div class="py-10">
          <img
            :src="require('../../../assets/images/board/0301_01.jpg')"
            alt=""
          />
        </div>
      </template>
    </MethodCharacteristic>
    <div class="w-full mx-auto py-20">
      <MethodAdvantage :title="'C-TMD 장점'" :advantages="advantages" />
    </div>
    <div class="w-full mx-auto py-20">
      <Outline
        :headerTitle="''"
        :title="'진동대를 이용한 TMD 성능실험'"
        :subTitle="'TMD(2020.09.15. 단국대학교 종합실험동)'"
      ></Outline>
      <div class="mt-12">
        <img
          class="w-full mb-4"
          :src="require('../../../assets/images/board/0301_02.jpg')"
          alt=""
        />
      </div>
      <div class="flex flex-wrap gap-4 justify-evenly px-8">
        <div
          class="textBox w-96 h-60 rounded-lg p-4"
          style="border: 1px solid #aaa"
          v-for="item in textContents"
          :key="item"
        >
          <div
            class="w-full h-14 rounded-lg flex items-center justify-center text-lg font-bold text-white"
            :style="`background:${item.bgColor}`"
          >
            {{ item.title }}
          </div>
          <ul class="py-4 flex flex-col gap-4" style="font-size: 16px">
            <li v-for="text in item.contents" :key="text">
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-full mx-auto py-20">
      <Outline :headerTitle="''" :title="'TMD 시공과정'"></Outline>
      <MethodFlow :flows="flows" />
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
import MethodCharacteristic from "./MethodCharacteristic.vue";
import MethodAdvantage from "./MethodAdvantage.vue";

import { method1 } from "@/constants/method";
import MethodFlow from "./MethodFlow.vue";

export default {
  setup() {
    const outline = method1.outline;
    const characteristic = method1.characteristic;
    const advantages = method1.advantages;
    const textContents = method1.textContents;
    const flows = method1.flows;
    return {
      outline,
      characteristic,
      advantages,
      textContents,
      flows,
    };
  },
  components: { Outline, MethodCharacteristic, MethodAdvantage, MethodFlow },
};
</script>

<style scoped>
.textBox {
}
@media (max-width: 801px) {
  .textBox {
    width: 100%;
  }
}
</style>

<template>
  <div class="board_category_wrapper">
    <div class="board_category">
      <div class="flex items-center h-14 w-full">
        <div class="category_btn btn_home w-16">
          <button
            class="cursor-pointer flex h-14 w-14 items-center justify-center"
            @click="$router.push('/')"
          >
            <fa-icon :icon="['fas', 'home']"></fa-icon>
          </button>
        </div>
        <div
          class="category_btn btn_arrow px-4"
          @click.stop="$emit('click-menu')"
        >
          <span>{{ $route.params.category }}</span>
          <slot name="menu"></slot>
        </div>
        <div
          class="category_btn btn_arrow px-4 font-bold"
          style="color: #c53528"
          @click.stop="$emit('click-submenu')"
        >
          <span>{{ $route.params.subcategory }}</span>
          <slot name="submenu"></slot>
        </div>
      </div>
      <div class="items-center path">
        <p class="whitespace-nowrap px-1">
          <fa-icon :icon="['fas', 'home']" style="color: #ddd"></fa-icon>
        </p>
        <p class="whitespace-nowrap px-1">{{ " > " }}</p>
        <p class="whitespace-nowrap px-1">{{ menu }}</p>
        <p class="whitespace-nowrap px-1">{{ " > " }}</p>
        <p class="whitespace-nowrap px-1">{{ submenu }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: String,
    submenu: String,
  },
};
</script>

<style scoped>
.board_category_wrapper {
  border: 1px solid #ddd;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.board_category {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.board_category_wrapper .category_btn {
  position: relative;
  border-right: 1px solid #ddd;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  width: 180px;
}
.board_category_wrapper .category_btn.btn_arrow {
  background: url("../../assets/images/icon/icnLocaArr.png") no-repeat 90%
    center;
}

.board_category_wrapper .category_btn.btn_home {
  border-left: 1px solid #ddd;
  display: flex;
  width: 60px;
}

.board_category_wrapper >>> .category_dropdown {
  border: 1px solid lightgray;
  border-top: 0;
  width: 180px;
  transition: all 200ms linear;
  background: white;
  overflow: hidden;
  height: 0;
}
.path {
  display: flex;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 300;
}
@media (max-width: 1100px) {
  .board_category_wrapper .category_btn {
    width: 50%;
  }
  .board_category_wrapper .category_btn.btn_home {
    display: none;
  }
  .board_category_wrapper >>> .category_dropdown {
    width: calc(100% + 1px);
  }
  .path {
    display: none;
  }
}
</style>

<template>
  <li
    class="row_wrapper box-border text-center relative w-full flex items-center h-40 sm:h-36 border-2"
  >
    <div class="inbox">
      <div class="index_box">{{ index }}</div>
      <div class="text_box">
        <span class="text-md md:text-lg text-left">
          {{ content }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    index: String,
    content: String,
  },
};
</script>

<style scoped>
.inbox {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-spacing: 0;
  border-collapse: collapse;
}
/* .inbox::before {
  content: "";
  position: absolute;
  left: -2px;
  right: -2px;
  top: 0;
  height: 1px;
} */
.row_wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: #1e3c66;
}
.index_box {
  min-width: 160px;
  height: 100%;
  color: white;
  font-size: 2.4rem;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #1e3c66;
}

.text_box {
  background: white;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  height: 100%;
  width: 100%;
}
.text_box::after {
}

@media (max-width: 498px) {
  .index_box {
    min-width: 100px;
    font-size: 1.8rem;
  }
}
</style>

<template>
  <div>
    <GeneralOutline :title="outlineTitle" :content="outlineContent" />
    <BusinessCharacteristic
      :sectionTitle="'공법개발 특징'"
      :content="`신기술 신공법 개발<br>
      기업부설연구소에서 연구개발<br>
      NB브레이스 공법, C-TMD 공법 등<br>
      신기술/신공법 개발 및 적용<br>
      산학연 협동 연구
      `"
      :wrapperStyle="'width:700px;height:520px;'"
    >
      <template v-slot:image>
        <div style="margin-top: -0.5rem">
          <img
            :src="require('../../../assets/images/board/sub0205_02.png')"
            alt=""
          />
        </div>
      </template>
    </BusinessCharacteristic>
    <GeneralOutline :headerTitle="' '" :title="`공법개발 영상`" />
    <div class="pb-32" style="margin-top: -4rem">
      <video controls="" style="width: 100%" playsinline>
        <source
          :src="require('../../../assets/videos/board/sub0205.mp4')"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</template>

<script>
import GeneralOutline from "../../Outline.vue";

import { business5 } from "@/constants/businessIntroduction";
import BusinessCharacteristic from "./BusinessCharacteristic.vue";

export default {
  setup() {
    const outlineTitle = business5.outline.title;
    const outlineContent = business5.outline.content;
    return {
      outlineTitle,
      outlineContent,
    };
  },
  components: { GeneralOutline, BusinessCharacteristic },
};
</script>

<style scoped></style>

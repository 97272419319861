<template>
  <div>
    <GeneralOutline :title="outlineTitle" :content="outlineContent" />
    <BusinessTable title="내진보강공사 플로우">
      <template v-slot:table-list>
        <ul style="display: flex; flex-direction: column; gap: 1rem">
          <BusinessFlowTableItem
            v-for="(item, idx) in flows"
            :key="item"
            :index="('0' + (idx + 1)).slice(0, 2)"
            :content="item"
          />
        </ul>
      </template>
    </BusinessTable>
  </div>
</template>

<script>
import GeneralOutline from "../../Outline.vue";

import { business4 } from "@/constants/businessIntroduction";
import BusinessTable from "./BusinessFlowTable.vue";
import BusinessFlowTableItem from "./BusinessFlowTableItem.vue";

export default {
  setup() {
    const outlineTitle = business4.outline.title;
    const outlineContent = business4.outline.content;

    const flows = business4.flows;
    return {
      outlineTitle,
      outlineContent,
      flows,
    };
  },
  components: {
    GeneralOutline,
    BusinessTable,
    BusinessFlowTableItem,
  },
};
</script>

<style scoped></style>

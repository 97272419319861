<template>
  <div>
    <GeneralOutline :title="outlineTitle" :content="outlineContent" />
    <BusinessCharacteristic
      :sectionTitle="'구조물안전진단 특징'"
      :innerTitle="characteristicTitle"
      :content="characteristicContent"
      :wrapperStyle="characteristicWrapperStyle"
    />
    <BusinessFlow title="구조물 안전진단" :flows="flows" />
  </div>
</template>

<script>
import GeneralOutline from "../../Outline.vue";

import { business3 } from "@/constants/businessIntroduction";
import BusinessCharacteristic from "./BusinessCharacteristic.vue";
import BusinessFlow from "./BusinessFlow.vue";

export default {
  setup() {
    const outlineTitle = business3.outline.title;
    const outlineContent = business3.outline.content;
    const characteristicTitle = business3.characteristic.title;
    const characteristicContent = business3.characteristic.content;
    const characteristicWrapperStyle = business3.characteristic?.wrapperStyle;

    const flows = business3.flows;
    return {
      outlineTitle,
      outlineContent,
      characteristicTitle,
      characteristicContent,
      characteristicWrapperStyle,
      flows,
    };
  },
  components: { GeneralOutline, BusinessCharacteristic, BusinessFlow },
};
</script>

<style scoped></style>

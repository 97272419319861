<template>
  <div class="board_content_section">
    <div class="flex flex-col items-center text-center mb-24">
      <h2 class="text-4xl">LOGO TYPES</h2>
      <div class="w-10 h-0.5 bg-black"></div>
      <br />
      <div>
        <img
          :src="require('../../../assets/images/board/logo_1.png')"
          alt="이음로고"
        />
        <h3 class="text-2xl my-4">LOGO 컨셉</h3>
        <p>
          이음이라는단 어의 뜻에걸맞게 3가지의 맞잡은 형상이 서로 이어주는
          모양을 이루고<br />가장 안정스러운 색상으로 내진이라는 안전의 단어에
          적합한 칼라를 구현하였습니다.
        </p>
      </div>
    </div>
    <div class="flex flex-col items-center text-center mb-24">
      <h2 class="text-4xl">TYPOGRAPHY</h2>
      <div class="w-10 h-0.5 bg-black"></div>
      <br />
      <div>
        <img
          :src="require('../../../assets/images/board/logo_1.png')"
          alt="이음로고"
        />
        <h3 class="text-2xl my-4">Text 컨셉(영문)</h3>
        <p>
          eum의 영문 표기에 심플한 서체를 사용함으로써<br />
          읽고 표시하기 쉬운 조합으로 구성하였습니다.
        </p>
        <br />
        <h3 class="text-2xl my-4">Text 컨셉(한글)</h3>
        <p>
          한글 표기는 가장 일반적이고 가독성이 뛰어난<br />
          나눔바른고딕으로 구성하며 그에 따른 구성은 다음과 같습니다.<br />
          (주)의 괄호는 건축의 곧은 모양을 의인화 하여 | 으로 표현하였습니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped></style>

export const method1 = {
  outline: {
    title: "C-TMD 개요",
    content: `<u style='text-decoration:none'>지진 발생시 건물의 진동방향과 반대방향으로 </u>
      <u style='text-decoration:none'>움직임으로써, 건물의 변위를 효과적으로 제어</u>
      <u style='text-decoration:none'>건물질량의 1%내외를 설치하여 제어하는 시스템입니다.</u> <br>
      <u style='text-decoration:none'>변위제어 기능과 함께 TMD의<br>방향별 질량과 강성을 달리하여</u>
      건물의  단변, 장변 방향을 동시에 제어하므로 경제성이 매우 우수합니다.`,
  },
  characteristic: {
    title: "C-TMD 특징",
    content: `C-TMD 제진 공법은 X, Y 방향의 질량을 달리하여 2축 방향의 제진이<br> 
      동시에 수행될 수 있는 동조질량감쇠 장치로 건축물 옥상에 설치되어<br> 
      기존 구조체 및 마감재 철거 없이 설치 가능하여 시공성이 매우 우수한 공법입니다.
      `,
  },

  advantages: [
    {
      title: "세계적으로 검증된<br>변위제어 공법",
      contents: [
        {
          description: "전 세계 주요 건축물에 설치된",
          strong: "검증된 공법",
        },
        {
          description: "풍하중과 지진하중을 동시에",
          strong: "제어하는 공법",
        },
        {
          description: "100년 이상 실제 지진에 안정성이",
          strong: "입증된 공법",
        },
      ],
      imgSrc: require("../assets/images/board/03_s01.png"),
    },
    {
      title: "기존 구조물 손상<br>최소화",
      contents: [
        {
          description: "건축물 지붕",
          strong: "필요 공간에 배치",
        },
        {
          description: "철거 폐기물을 최소화한",
          strong: "친환경 공법",
        },
        {
          description: "별도 마감공정",
          strong: "불필요",
        },
      ],
      imgSrc: require("../assets/images/board/03_s02.png"),
    },
    {
      title: "공사 중 건물 사용성<br>확보",
      contents: [
        {
          description: "전 공정",
          strong: "실외 공사",
        },
        {
          description: "학교시설 학기 중",
          strong: "시공 가능",
        },
        {
          description: "공간 기간 중 내부 실",
          strong: "사용 가능",
        },
      ],
      imgSrc: require("../assets/images/board/03_s03.png"),
    },
    {
      title: "대용량 TMD 배치로<br>설치위치 최소화",
      contents: [
        {
          description: "집중 배치로 시공성 향상을 통한",
          strong: "공기단축",
        },
        {
          description: "기존 설비와의",
          strong: "간섭 최소화",
        },
        {
          description: "분진 소음 최소화로",
          strong: "민원발생 방지",
        },
      ],
      imgSrc: require("../assets/images/board/03_s04.png"),
    },
    {
      title: "보강 후 건물 외관의<br>변화 없음",
      contents: [
        {
          description: "입면 변화 없이 건물",
          strong: "디자인 유지",
        },
        {
          description: "보강 후 기존",
          strong: "조망, 채광 유지",
        },
        {
          description: "별도 가설 공정이",
          strong: "불필요",
        },
      ],
      imgSrc: require("../assets/images/board/03_s05.png"),
    },
    {
      title: "양방향 동시 제어로<br>경제성 향상",
      contents: [
        {
          description: "2축 동시 제어로",
          strong: "설치 개수 최소화",
        },
        {
          description: "X,Y축 질량비를 달리하여",
          strong: "양방향 효율적 제어",
        },
        {
          description: "각 방향별 질량 보정이 가능하여",
          strong: "유지보수 편의성 증대",
        },
      ],
      imgSrc: require("../assets/images/board/03_s06.png"),
    },
    {
      title: "PC 및 모바일로<br>실시간 모니터링 가능",
      contents: [
        {
          description: "건물의 진동을",
          strong: "실시간 모니터링",
        },
        {
          description: "특정 이벤트가 자동 계측되어",
          strong: "구조물 진동 상황 실시간 기록",
        },
        {
          description: "TMD 성능을 실시간으로 확인하므로",
          strong: "유지관리 우수",
        },
      ],
      imgSrc: require("../assets/images/board/03_s07.png"),
    },
  ],

  textContents: [
    {
      title: "실험 장비",
      contents: [
        `- 5톤급 진동대\r\n(국가 지정대학 중점 연구소 지원)`,
        `- 레이져 변위계(Micro-Epsilon)`,
        `- 가속도계(PCB Piezotronics)`,
      ],
      bgColor: "#57a8e9",
    },
    {
      title: "입력 하중",
      contents: [
        `- Sine Sweep(0.5 - 3.0Hz)`,
        `- Sinusoidal Wave`,
        `- Earthquake Simulation`,
        `- Random Wave`,
      ],
      bgColor: "#7ecac8",
    },
    {
      title: "실험 Case",
      contents: [
        `- X,Y 일방향 진동 실험`,
        `- 감쇠장치의 감쇠력\r\n조정(무감쇠, 감쇠력 0~5단계)`,
      ],
      bgColor: "#d1d671",
    },
  ],
  flows: [
    {
      title: "먹매김 및 설치 위치 셋팅",
      content: `
            기둥 위치와 앵커 설치<br>
            위치가 정확하게<br>
            파악 되어야 함`,
    },
    {
      title: "철거 공사",
      content: ``,
    },
    {
      title: "하부 프레임 설치",
      content: `
          앵커 위치와 기둥,보 위치<br>
          재확인,철골 수직,수평도<br>
          검측 실시`,
    },
    {
      title: "TMD조립 및 설치 계측장치 설치",
      content: `
          하부 프레임과 X방향 프레임<br>
          사이 이격 여부 확인 및 충진,<br>
          장 실험으로 작동성 확인 `,
    },
    {
      title: "방수 공사",
      content: `
          신규 방수재 호환성 검토,<br>
          방수 건조 상태 확인 후<br>
          무근 타설
      `,
    },
    {
      title: "구체 바닥 및 기타 마감",
      content: `기존 마감과 동일하게<br>
          마감 처리, 포스트 커버/<br>
          이질재 접합부 수밀 코킹`,
    },
    {
      title: "TMD 외부 막 설치",
      content: `
          강우,강설 및<br>
          분진 차단 여부 확인/
  `,
    },
  ],
};

export const method2 = {
  outline: {
    title: "NB 브레이스 개요",
    content: `전세계적으로 가장 널리 사용되고 있는 횡력 저항 시스템인 철골 브레이스 공법은<br>
  우수한 내진 보강 공법임에도 횡력 집중으로 인한 기초 보강이 필요한 경우가 많습니다.<br>
  NB브레이스 공법은 고력볼트의 토크압을 이용하여 지진 하중 집중으로 인한 기초의 반력을<br>
  최소화 함으로써 기초 보강이 필요없는 횡력 저항 시스템입니다.<br>
  또한 가새 부재의 압축 좌굴로 인한 취성 파괴 거동을 항복 토크 압 초과시 슬롯홀을 따라<br>
  미끄러짐을 유도하여 구조물의 연성 거동을 보장합니다.<br>
  가새의 장점인 우수한 횡강성과 연성 거동을 보장하는 비좌굴 시스템의 장점만을 조합하여<br>
  최고의 내진 보강 공법임을 자신합니다.<br>`,
  },
  characteristic: {
    title: "NB 브레이스 특징",
    content: `NB 브레이스 내진보강공법은<br>고력볼트의 토크압을 이용해
      필요 미끄럼강도를<br>기초보강이 없도록 건물에 맞게 조정이 가능하고,<br>
      특허받은 견고한 접합상세로 기존 구조체와<br>일체화 거동을 보장하는 비좌굴 가새 공법입니다.
      `,
  },

  advantages: [
    {
      title: "횡강성이 우수한<br>가새 공법",
      contents: [
        {
          description: `가새 형태는 전통적인 횡력 저항
              시스템으로 사각형인
              프레임 형태에 비해 10 배 이상의
              횡강성이 크므로 지진발생시
              기존 구조체의 손상을 최소화하므로
              전세계적으로 가장 널리
              사용되고 있는
              내진보강공법 입니다.
              `,
        },
      ],
      imgSrc: require("../assets/images/board/0302_s01.png"),
    },
    {
      title: "슬롯 혹이 적용된<br>마찰 가새<br>(Non-Buckling)",
      contents: [
        {
          description: `지진 하중 발생 시 슬롯 홀을 따라
                  변형되는 NB브레이스에 의해
                  응력이 집중되는 것을
                  방지함으로써 보강부재의 
                  손상을 최소화하고
                  기초보강이 필요없는 공법입니다.`,
        },
      ],
      imgSrc: require("../assets/images/board/0302_s02.png"),
    },
    {
      title: "견고한 접합부<br>(내부/외부)",
      contents: [
        {
          description: `
                    NB브레이스는 설치가 용이한
                    접합방법 사용으로
                    시공성이 뛰어나며,
                    고강도 무수축 모르타르를 타설하여
                    접합부를 더욱 견고하게
                    유지시킵니다.
                    `,
        },
      ],
      imgSrc: require("../assets/images/board/0302_s03.png"),
    },
    {
      title: "작업공간의 최소화",
      contents: [
        {
          description: `6조각으로 나뉜
                    NB브레이스는
                    소운반이 가능 작업공간을
                    최소화 할 수 있으며
                    운반 후 Splice로 연결하여
                    보강위치에 설치`,
        },
      ],
      imgSrc: require("../assets/images/board/0302_s04.png"),
    },
  ],
  performance: {
    title: "NB 브레이스 공법 성능 검증",
    content: `      
          NB 브레이스 성능 검증을 위하여 실물 크기의 실험체를 제작하여 NB디바이스 성능 평가와<br>
          NB브레이스 유시동적 실험을 실시하였습니다.<br>
          가력 및 측정 장치를 이용, NB디바이스 성능 평가를 실시하여 토크압과 최대 하중과의 관계식을 유도,<br>
          최적의 설계식에 의한 토크압은 항복 강도의 1.75배임을 확인했으며, NB 브레이스 유사동적 실험을 통해<br>
          각 단계별 NB 브레이스의 성능을 검증하였습니다.<br>`,
  },
  flows: [
    {
      title: "기존 마감재 철거",
      content: `
            사전 철거협의로<br>
            오시공 방지
            `,
    },
    {
      title: "보양 및 가설재 설치",
      content: `
            가설재 설치 시<br>
            안정성 확보 및 내부<br>
            사용자 편의성 제공
            `,
    },
    {
      title: "NB브레이스 제작",
      content: `
              가자재 선 발주 및<br>
              가공장 품질 관리를 통하여<br>
              뛰어난 품질 확보
          `,
    },
    {
      title: "NB브레이스 설치",
      content: `
              자재 반입 전<br>
              사전 공사 준비를<br>
              통하여 공사기간 단축
            `,
    },
    {
      title: "무수축<br>모르타르타설",
      content: `
          현장 시공 진행에 따라서<br>
          필요한 각종 검사를<br>
          실시하여 품질 확인
      `,
    },
    {
      title: "마감 공정",
      content: `마감 공사중<br>마감 품질 확인`,
    },
  ],
};

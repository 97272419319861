<template>
  <div class="board_content_wrapper">
    <component :is="currentContent"></component>
  </div>
</template>

<script>
import Greeting from "@/components/Board/About/Greeting.vue";
import Business from "@/components/Board/About/Business.vue";
import Ci from "@/components/Board/About/Ci.vue";
import History from "../components/Board/About/History.vue";

import Business1 from "../components/Board/Business/Business1.vue";
import Business2 from "../components/Board/Business/Business2.vue";
import Business3 from "../components/Board/Business/Business3.vue";
import Business4 from "../components/Board/Business/Business4.vue";
import Business5 from "../components/Board/Business/Business5.vue";

import Method1 from "../components/Board/Method/Method1.vue";
import Method2 from "../components/Board/Method/Method2.vue";

import Earning1 from "../components/Board/Earning/Earning1.vue";
import Earning2 from "../components/Board/Earning/Earning2.vue";

import Qna from "../components/Board/CS/Qna.vue";
import Employing from "../components/Board/CS/Employing.vue";
import Files from "../components/Board/CS/Files.vue";
import Map from "../components/Board/CS/Map.vue";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  components: {
    Greeting,
    Business,
    Ci,
    History,
    Business1,
    Business2,
    Business3,
    Business4,
    Business5,
    Method1,
    Method2,
    Earning1,
    Earning2,

    Qna,
    Employing,
    Files,
    Map,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const currentMenu = computed(() => route.params.category);
    const currentSubmenu = computed(() => route.params.subcategory);
    const menus = computed(() => store.getters["menus"]);

    const currentContent = computed(() => {
      if (!menus.value) return null;

      const menu = menus.value.find((menu) => menu.title === currentMenu.value);

      const submenu = menu.submenus.find(
        (submenu) => submenu.title === currentSubmenu.value
      )?.component;
      // console.log("menu", menu, "sumenu", submenu, "submenus", menu.submenus);
      if (!submenu) {
        route.params.subcategory = menu.submenus[0].component;
        router.push({
          name: "BoardContent",
          params: {
            ...route.params,
            subcategory: menu.submenus[0].title,
          },
        });
        return menu.submenus[0].component;
      }
      return submenu ?? menu.submenus[0].component;
    });
    return {
      currentContent,
    };
  },
};
</script>

<style>
@import url(../styles/board.css);
</style>

<template>
  <div id="map"></div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  name: "KakaoMap",
  setup() {
    return {
      ...useKakaoMap(),
    };
  },
};
const useKakaoMap = () => {
  /* global kakao */
  const map = ref(null);
  const markers = ref([]);
  function initMap() {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(37.5655811, 127.055736), //	X :127.055736, Y :37.5655811
      level: 5,
    };

    //지도 객체를 등록합니다.
    //지도 객체는 반응형 관리 대상이 아니므로 initMap에서 선언합니다.
    map.value = new kakao.maps.Map(container, options);
    displayMarker([
      {
        title: "(주) 이음건설산업",
        latlng: new kakao.maps.LatLng(37.5655811, 127.055736),
      },
    ]);
  }
  onMounted(() => {
    if (window.kakao && window.kakao.maps) {
      initMap();
    } else {
      const script = document.createElement("script");

      script.onload = () => kakao.maps.load(initMap);
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=" +
        process.env.VUE_APP_KAKAO_API_KEY;
      document.head.appendChild(script);
    }
  });
  const displayMarker = (positions) => {
    if (markers.value.length > 0) {
      markers.value.forEach((item) => item.setMap(null));
    }

    positions.forEach((pos) => {
      const infoWindow = new kakao.maps.InfoWindow({
        removable: false,
        content: `<div style="padding: 5px 10px;width: fit-content; white-space:nowrap">
          <a target="_blank" href="https://map.kakao.com/?urlX=512307.0&urlY=1129466.0&name=%EC%84%9C%EC%9A%B8%20%EB%8F%99%EB%8C%80%EB%AC%B8%EA%B5%AC%20%EA%B3%A0%EB%AF%B8%EC%88%A0%EB%A1%9C%2081&map_type=TYPE_MAP&from=roughmap">
           서울 동대문구 고미술로 81 평화빌딩 309호 
          </a>
        </div>`,
      });
      const marker = new kakao.maps.Marker({
        map: map.value,
        position: pos.latlng,
        title: pos.title,
      });

      markers.value.push(marker);
      infoWindow.open(map.value, marker);
    });

    const bounds = positions.reduce(
      (bounds, position) => bounds.extend(position.latlng),
      new kakao.maps.LatLngBounds()
    );
    map.value.setBounds(bounds);
  };
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 400px;
}
</style>

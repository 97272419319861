<template>
  <div class="w-full mx-auto">
    <div class="item_container">
      <EarningImageItem
        v-for="item in listData"
        :key="item.list_pk"
        :imgSrc="item.path + item.save_name"
        :title="item.title"
        :description="item.title"
        @click="onClickItem(item)"
      />
    </div>
  </div>
</template>

<script>
import EarningImageItem from "./EarningImageItem.vue";

// import { earningImages } from "../../../constants/earning";
import { onBeforeMount, ref } from "vue-demi";
import Board from "../../../utils/api/Board";
import { useRouter } from "vue-router";
export default {
  setup() {
    const listData = ref([]);
    onBeforeMount(() => {
      (async function () {
        const { list } = await Board.getList({
          menu: "photos",
          type: "이미지",
        });
        listData.value = list;
      })();
    });

    const router = useRouter();
    const onClickItem = (item) => {
      router.push(`/board/주요실적/사진/photo/${item.list_pk}`);
    };
    return {
      listData,
      onClickItem,
    };
  },
  components: { EarningImageItem },
};
</script>

<style scoped>
.item_container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0px auto;
  padding: 30px;
  background: none;
}
</style>

export const earnings = [
  {
    year: "2021",
    tableItem: [],
  },
  {
    year: "2020",
    tableItem: [],
  },
  {
    year: "2019",
    tableItem: [],
  },
  {
    year: "2018",
    tableItem: [
      {
        title: "하늘내린파크",
        num: "2",
        date: "2018.01",
        description: "",
      },
      {
        title: "군자기지 변전소",
        num: "18",
        date: "2018.01",
        description: "",
      },
      {
        title: "군자기지 후생관",
        num: "14",
        date: "2018.01",
        description: "",
      },
      {
        title: "수서기지 검차사무소",
        num: "5",
        date: "2018.01",
        description: "",
      },
      {
        title: "수서기지 모터카실",
        num: "5",
        date: "2018.01",
        description: "",
      },
      {
        title: "수서기지 변전소",
        num: "24",
        date: "2018.01",
        description: "",
      },
      {
        title: "수서기지 차량사업소",
        num: "24",
        date: "2018.01",
        description: "",
      },
      {
        title: "수서기지 현업동 신답역",
        num: "3",
        date: "2018.01",
        description: "",
      },
      {
        title: "신정기지 검수고",
        num: "48",
        date: "2018.01",
        description: "",
      },
      {
        title: "신정기지 변전소",
        num: "12",
        date: "2018.01",
        description: "",
      },
      {
        title: "신정기지 토목동",
        num: "4",
        date: "2018.01",
        description: "",
      },
      {
        title: "영주국유림관리소 (직원복지관사)",
        num: "4",
        date: "2018.05",
        description: "",
      },
      {
        title: "합천군 제2청사",
        num: "18",
        date: "2018.06",
        description: "",
      },
      {
        title: "삼천포(전력) : 집진기 및 처리제어실(1,2호기)",
        num: "16",
        date: "2018.11",
        description: "",
      },
      {
        title: "삼천포(일반) : 종합사무실(지원처)",
        num: "16",
        date: "2018.11",
        description: "",
      },
      {
        title: "삼천포(일반) : 종합사무실(운영처)",
        num: "10",
        date: "2018.11",
        description: "",
      },
      {
        title: "삼천포(일반) : 중기정비고",
        num: "6",
        date: "2018.11",
        description: "",
      },
      {
        title: "영동(일반) : 발전소 사무실(총무부동)",
        num: "10",
        date: "2018.11",
        description: "",
      },
    ],
  },
  {
    year: "2017",
    tableItem: [
      { title: "율면사무소", num: "	5", date: "2017.03	" },
      { title: "방배119안전센터", num: "	2", date: "2017.03	" },
      { title: "영동119안전센터", num: "	2", date: "2017.04	" },
      { title: "수서119안전센터", num: "	4", date: "2017.04	" },
      { title: "개포119안전센터", num: "	4", date: "2017.04	" },
      { title: "신교119안전센터", num: "	4", date: "2017.04	" },
      { title: "연건119안전센터", num: "	2", date: "2017.04	" },
      { title: "신영119안전센터", num: "	2", date: "2017.04	" },
      { title: "종로119안전센터", num: "	2", date: "2017.04	" },
      { title: "숭인119안전센터", num: "	4", date: "2017.04	" },
      { title: "119안전센터", num: "	5", date: "2017.04	" },
      { title: "태백국유림관리소", num: "	2", date: "2017.04	" },
      { title: "육림관", num: "	4", date: "2017.04	" },
      { title: "단풍관", num: "	8", date: "2017.04	" },
      { title: "양천구보건소", num: "	8", date: "2017.05	" },
      { title: "양천구의회청사", num: "	9", date: "2017.05	" },
      { title: "의왕시 농업기술센터", num: "	5", date: "2017.05	" },
      { title: "부곡동주민센터", num: "	8", date: "2017.06	" },
      { title: "중소기업 성장지원센터	", num: "20", date: "2017.06	" },
      { title: "수내2동 청사", num: "	3", date: "2017.07	" },
      { title: "일산종합복지회관	", num: "12", date: "2017.08	" },
      { title: "능곡동종합복지회관", num: "	4", date: "2017.08	" },
      { title: "원당종합사회복지회관", num: "	9", date: "2017.08	" },
      { title: "신도동종합복지회관", num: "	6", date: "2017.08	" },
      { title: "신선동 주민센터", num: "	6", date: "2017.08	" },
      { title: "청학1동 주민센터", num: "	4", date: "2017.08	" },
      { title: "청학2동 주민센터	", num: "10", date: "2017.08	" },
      { title: "봉래1동 주민센터	", num: "10", date: "2017.08	" },
      { title: "동삼2동 주민센터", num: "	2", date: "2017.08	" },
      { title: "동삼3동 주민센터", num: "	5", date: "2017.08	" },
      { title: "부곡119안전센터", num: "	5", date: "2017.08	" },
      { title: "경기도소방학교(본관동)	", num: "15", date: "2017.09	" },
      { title: "경기도소방학교(훈련부탑)", num: "	6", date: "2017.09	" },
      { title: "여주종합운동장", num: "	8", date: "2017.12	" },
      { title: "식당동", num: "	2", date: "2017.12	" },
      { title: "문화재연구동", num: "	2", date: "2017.12	" },
      { title: "동대문구민회관	", num: "16", date: "2017.12	" },
      { title: "화랑군부대아파트	", num: "12", date: "2017.12	" },
      { title: "천도아파트(B동)	", num: "22", date: "	2017.12" },
    ],
  },
  {
    year: "2017이전",
    tableItem: [],
  },
];

export const earningImages = [
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131785_7836_380x280.jpg"),
    title: "인천항 한진사무실 완공후 사진",
    description: "인천항 한진사무실 완공후 사진",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131725_5102_380x280.jpg"),
    title: "인천항 ICT 사무실",
    description: "인천항 ICT 사무실",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131691_1154_380x280.jpg"),
    title: "무안공항",
    description: "무안공항",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131668_2127_380x280.jpg"),
    title: "여주 종합경기장",
    description: "여주 종합경기장",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131645_3673_380x280.jpg"),
    title: "숭인119안전센터",
    description: "숭인119안전센터",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131613_3266_380x280.jpg"),
    title: "신영119안전센터",
    description: "신영119안전센터",
    date: "2021-04-23",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131580_9117_380x280.jpg"),
    title: "신교119안전센터",
    description: "신교119안전센터",
    date: "2021-03-21",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131552_1801_380x280.jpg"),
    title: "개포119안전센터",
    description: "개포119안전센터",
    date: "2021-03-21",
  },
  {
    imgSrc: require("../assets/images/post/thumb-561f2f382075c0af84fde6d202f2a28d_1619131471_478_380x280.jpg"),
    title: "율면사무소",
    description: "율면행정복지센터",
    date: "2021-03-21",
  },
];

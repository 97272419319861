export const tabContents = [
  {
    title: "신축구조설계",
    content: `
          구조설계 전문가 집단으로
          <br />최고의 기술력을 바탕으로 <br />최상의 구조설계 엔지니어링을
          제공합니다. <br />일반건축물 부터 초고층건물,
          <br />
          장스팬건물, 입체트러스, 현수구조, PEB,
          <br />기하비선형 및 재료비선형 해석까지 <br />다양한 분야에 걸쳐
          구조엔지니어링 경험과
          <br />
          노하우를 가지고 있습니다.
        `,
  },
  {
    title: "내진성능평가",
    content: `다수의 건물에 내진성능평가 및<br>
        내진보강설계를 진행하였습니다.<br>
        관공서 및 초/중/고등학교 그리고<br>
        대학교를 비롯해 특수구조물(토목구조물) 까지<br>
        수많은 내진성능평가를 성공적으로<br>
        수행하였습니다.<br>
        <br>
        기존 구조물에 대한 현장조사 부터<br>
        개별 부재의 유효강성을 고려하여<br>
        국내외 기준에 맞는 정확한내진성능을<br>
        평가해 드립니다.`,
  },
  {
    title: "구조물안전진단",
    content: `
          <u>우수한 전문가와 최신진단장비를 활용하여 취약시설에 대한</u>
          <u>철저한 진단 및 문제원인에 대한 적절한 보강대안을 제시하는</u>
          <u> 국내 최고의 안전진단 구조전문가 집단입니다.</u>
          <u>건설공사 안전점검 및 공동주택 재건축 판정 안전진단, 공동주택 하자판정 안전진단을 수행합니다.</u>
        `,
  },
  {
    title: "내진보강공사",
    content: `
        <u>비선형해석 및 유한요소해석을 통해 구조물의 변위를</u>
        <u>저감시키는 최신 진동제어 기술을 엔지니어링 합니다.</u>
        <br>
        <u>변위의존형과 속도의존형 제진장치(Damping Device)를</u>
        <u> 적재적소에 설치하여 지진에너지를 소산시키고 진동 발생후 제진장치만 교체하면</u>
        전체 구조물에 대한 보수.보강없이 최고의 구조물 상태를 유지할수 있습니다. 
`,
  },
  {
    title: "공법개발",
    content: `국내 유수의 대학교와 연구소,<br>
        그리고 (주)이음건설산업이 산학연 협력하여<br>
        내진 및 제진에 특화된 새롭고 우수한 공법을<br>
        끊임없이 연구/개발하고 있으며,<br>
        개발된 새로운 공법은<br>
        특허 및 성능인증을 취득하여<br>
        설계 및 현장에 바로 적용, 사용되고 있습니다.`,
  },
];

<template>
  <Outline :headerTitle="''" :title="title" :content="``" />
  <div class="w-full mx-auto" style="margin-top: -5rem; max-width: 1200px">
    <ul class="w-full relative text-center flex flex-wrap justify-center">
      <TableCard
        v-for="(advantage, idx) in advantages"
        :key="advantage"
        :advantage="advantage"
        style="visiblility: hidden; opacity: 0"
        v-animate:2
        :animation="
          idx % 2 === 0
            ? 'animate__animated animate__fadeInUp'
            : 'animate__animated animate__fadeInDown'
        "
      />
    </ul>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
import TableCard from "../../TableCard.vue";
export default {
  components: { Outline, TableCard },
  props: {
    title: String,
    advantages: Array,
  },
};
</script>

<style></style>

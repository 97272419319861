<template>
  <div class="content">
    <article id="post">
      <header>
        <h2>{{ postData.title }}</h2>
      </header>
      <section class="post_info">
        <div class="flex">
          <div class="profile_img" style="width: 50px; height: 50px">
            <img
              style="min-width: 50px; min-height: 50px"
              class="rounded-full"
              :src="require('../assets/images/icon/no_profile.gif')"
              alt=""
            />
          </div>
          <div class="flex flex-col flex-wrap px-4">
            <p class="font-bold">{{ postData?.writer ?? "이음" }}</p>
            <div class="text_info">
              <span>View {{ numberFormat(postData?.hit) }}회</span>
              <span>Date {{ postData?.enrollment_date }}</span>
            </div>
          </div>
        </div>
        <div class="flex gap-5 p-4 w-full justify-end info_btn">
          <button @click="$router.push('/board/주요실적/사진')">
            <fa-icon :icon="['fas', 'list']" />
          </button>
          <!-- <button><fa-icon :icon="['fas', 'reply']" /></button> -->
        </div>
      </section>
      <section class="post_content">
        <div v-html="postData?.content"></div>
      </section>
      <ul class="post_list">
        <li class="list_item" v-if="prevPage?.list_pk">
          <span class="move">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
            <fa-icon :icon="['fas', 'chevron-up']"></fa-icon>
            이전글
          </span>
          <a class="cursor-pointer" @click="onClickPageList(prevPage)">{{
            prevPage.title
          }}</a>
          <span class="date">{{ prevPage.enrollment_date.slice(0, 10) }}</span>
        </li>
        <li class="list_item" v-if="nextPage?.list_pk">
          <span class="move">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
            <fa-icon :icon="['fas', 'chevron-down']"></fa-icon>
            다음글
          </span>
          <a class="cursor-pointer" @click="onClickPageList(nextPage)">{{
            nextPage.title
          }}</a>
          <span class="date">{{ nextPage.enrollment_date.slice(0, 10) }}</span>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue-demi";
import Board from "../utils/api/Board";
import { numberFormat } from "../utils/numberFormat";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const postData = ref({});
    const prevPage = ref({});
    const nextPage = ref({});

    const route = useRoute();
    const router = useRouter();

    onBeforeMount(() => {
      (async function () {
        const { next, pre, detail } = await Board.getDetail(
          route.params.photoId
        );
        postData.value = detail;
        prevPage.value = pre;
        nextPage.value = next;
      })();
    });
    const onClickPageList = (page) => {
      const { menu, list_pk } = page;
      if (menu === "files") {
        router.push({
          name: "Post",
          params: {
            ...route.params,
            postId: list_pk,
          },
        });
      } else if (menu === "photos") {
        router.push(`/board/주요실적/사진/photo/${page.list_pk}`);
      }
    };
    return {
      numberFormat,
      postData,
      prevPage,
      nextPage,
      onClickPageList,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0px auto;
  padding: 30px 15px;
  background: none;
}
#post {
  margin-bottom: 20px;
  background: white;
  box-sizing: border-box;
}
#post header h2 {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  padding: 15px;
  padding-left: 10px;
  background-color: #f7f7f7;
  font-size: 18px;
  display: flex;
  align-items: center;
}

#post .post_info {
  color: #666;
  margin: 0;
  border-bottom: 1px solid #ecf0f1;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#post .post_info .profile_img {
  width: 50px;
  height: 50px;
  margin-left: 1rem;
}
#post .post_info .text_info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
#post .post_info .text_info span {
  padding-right: 1rem;
}

#post .post_content {
  margin: 10px 0 30px;
  width: 100%;
  line-height: 1.7em;
  min-height: 200px;
  word-break: break-all;
  overflow: hidden;
  padding-top: 50px;
}
#post .post_content {
  margin: 10px 0 30px;
  width: 100%;
  line-height: 1.7em;
  min-height: 200px;
  word-break: break-all;
  overflow: hidden;
  padding-top: 50px;
}

#post .post_list {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
#post .post_list .list_item {
  border-top: 1px solid #ecf0f1;
  padding: 13px;
  font-size: 13px;
}
#post .post_list .list_item:hover {
  background: #f6f6f6;
}
#post .post_list .list_item:last-child {
  border-bottom: 1px solid #ecf0f1;
}
#post .post_list .list_item .move {
  padding-right: 20px;
  color: #b3b3b3;
}
#post .post_list .list_item .date {
  float: right;
  color: #b3b3b3;
}

@media (min-width: 500px) {
  #post .info_btn {
    width: fit-content;
  }
}
</style>

<template>
  <Header />
  <Sidebar />
  <router-view />
  <Footer />
  <SideToolbar />
  <div
    class="fixed right-4 bottom-4"
    style="z-index: 9999; background: rgb(255, 255, 255, 0.7)"
  >
    <button @click="onClickTop" id="btn_top">
      <fa-icon :icon="['fas', 'arrow-up']"></fa-icon>
    </button>
  </div>
</template>
<script>
import "animate.css";
import Header from "@/components/layout/Header.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import Footer from "@/components/layout/Footer.vue";
import SideToolbar from "./components/layout/SideToolbar.vue";
import { onBeforeMount } from "vue-demi";

import axios from "@/utils/api/index";

export default {
  components: {
    Header,
    Sidebar,
    Footer,
    SideToolbar,
  },
  setup() {
    const onClickTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    onBeforeMount(() => {
      (async () => {
        const { ip } = await (await fetch("https://jsonip.com")).json();
        axios.post("/saveVisitor", { ip });
      })();
    });
    return {
      onClickTop,
    };
  },
};
</script>
<style>
@import url(./styles/reset.css);
@import url(./styles/global.css);
/* @import url(./styles/animation.css); */

@import url(./styles/modal.css);
@import url(./styles/variables.css);

#btn_top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #333;
  color: #333;
  text-align: center;
  font-size: 15px;
  z-index: 990;
  background: rgba(255, 255, 255, 0.5);
}
#btn_top:hover {
  border-color: #3059c7;
  background: #3059c7;
  color: #fff;
}
</style>

export const animate = {
  mounted(el, bind) {
    function addAnimationClass(targetEl) {
      const toAdd = targetEl.getAttribute("animation");
      const classList = toAdd.split(" ");
      classList.map((v) => targetEl.classList.add(v));
      targetEl.style.setProperty("visibility", "visible");
      targetEl.style.setProperty("opacity", 1);
    }

    function callIntersectionApi() {
      const options = {
        root: null,
        threshold: 0.5,
        rootMargin: "0px",
      };
      if (bind.arg && !isNaN(bind.arg)) {
        options.threshold = Number(bind.arg) / 10;
      }

      const addClassCallback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            addAnimationClass(entry.target);
            observer.unobserve(entry.target);
          }
        });
      };
      const classAddIO = new IntersectionObserver(addClassCallback, options);
      setTimeout(() => {
        classAddIO.observe(el);
      }, 500);
    }

    window.IntersectionObserver ? callIntersectionApi() : addAnimationClass(el);
  },
};

<template>
  <div class="board_content_section py-14">
    <div class="timeline">
      <HistoryItem
        :year="2020"
        :history="[
          {
            month: '08월',
            content: 'TMD(동흡진장치) 관련 특허 등록',
          },
          {
            month: '08월',
            content: '연구개발 전담부서 인정',
          },
          {
            month: '02월',
            content: '본사 소재지 변경',
          },
        ]"
        :image="require('../../../assets/images/board/his01.jpg')"
      ></HistoryItem>
      <HistoryItem
        :year="2019"
        :history="[
          {
            month: '07월',
            content: '시설물유지관리업 면허 취득 (동대문-19-29-3)',
          },
        ]"
      ></HistoryItem>
      <HistoryItem
        :year="2017"
        :history="[
          {
            month: '07월',
            content: '대표이사 이호경 취임',
          },
          {
            month: '07월',
            content: '주식회사 이음건설산업 설립',
          },
        ]"
      ></HistoryItem>
    </div>
  </div>
</template>

<script>
import HistoryItem from "./HistoryItem.vue";
export default {
  setup() {},
  components: { HistoryItem },
};
</script>

<style scoped>
.timeline {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 50px auto;
  overflow: hidden;
}
.timeline:before {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  background-color: #eee;
  content: "";
}
</style>

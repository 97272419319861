<template>
  <div class="w-full py-10 px-4 md:px-20" style="background: #f8f8f8">
    <Outline :headerTitle="' '" :title="sectionTitle" :content="content" />
    <div
      class="w-full flex items-center justify-center -mt-6"
      style="margin-top: -6rem visiblility: hidden; opacity: 0"
      v-animate:1
      animation="animate__animated animate__fadeInUp"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
export default {
  components: { Outline },
  props: {
    sectionTitle: String,
    content: String,
  },
};
</script>

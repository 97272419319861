<template>
  <div ref="menuRef" class="q-menu" id="backtotop">
    <nav>
      <div class="menu_item">
        <router-link to="/" class="btn_toolbar"
          ><fa-icon :icon="['fas', 'home']"></fa-icon
          ><span>Home</span></router-link
        >
      </div>
      <div class="menu_item">
        <router-link to="/board/About이음" class="btn_toolbar"
          ><fa-icon :icon="['fas', 'building']"></fa-icon
          ><span>About</span></router-link
        >
      </div>
      <div class="menu_item">
        <router-link to="/board/고객지원/찾아오시는길" class="btn_toolbar"
          ><fa-icon :icon="['fas', 'location']"></fa-icon
          ><span>Location</span></router-link
        >
      </div>
      <div class="menu_item">
        <button class="btn_toolbar text-white" @click="onClickTop">
          <fa-icon :icon="['fas', 'angle-up']"></fa-icon><span>Top</span>
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";

import _ from "lodash";

export default {
  setup() {
    const menuRef = ref(null);
    onMounted(() => {
      if (window.scrollY > 0) {
        menuRef.value.style.setProperty("visibility", "visible");
        menuRef.value.style.setProperty("opacity", "1");
      }
      window.addEventListener(
        "scroll",
        _.throttle(() => {
          if (window.scrollY > 0) {
            menuRef.value.style.setProperty("display", "block");
            setTimeout(() => {
              menuRef.value.style.setProperty("visibility", "visible");
              menuRef.value.style.setProperty("opacity", "1");
            }, 0);
          } else {
            menuRef.value.style.setProperty("visibility", "hidden");
            menuRef.value.style.setProperty("opacity", "0");
            setTimeout(() => {
              menuRef.value.style.setProperty("display", "none");
            }, 200);
          }
        }),
        50
      );
    });

    const onClickTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return {
      menuRef,
      onClickTop,
    };
  },
};
</script>

<style scoped>
#backtotop {
  position: fixed;
  bottom: 35%;
  right: 10px;
  z-index: 1000;
  display: none;
  transition: all 200ms linear;
  opacity: 0;
}
@media (max-width: 1200px) {
  #backtotop {
    display: none !important;
  }
}
#backtotop .menu_item {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.5em;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  margin: 10px 0;
  border-radius: 100px;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
#backtotop .menu_item:hover {
  background: #4166ab;
}
#backtotop .menu_item .btn_toolbar {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  text-decoration: none;
}
#backtotop .menu_item .btn_toolbar span {
  font-size: 0.7em;
  display: inline-block;
  position: absolute;
  top: 15%;
  right: 70px;
  opacity: 0;
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 3px 3px;
  background: #4166ab;
  color: #fff;
  margin-top: -2px;
  line-height: 1em;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

#backtotop .menu_item .btn_toolbar span::after {
  content: "";
  display: block;
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -5px;
  border: 5px solid transparent;
  border-left-color: #4166ab;
}

#backtotop .menu_item .btn_toolbar:after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: -5px;
  margin-top: -10px;
  top: 50%;
  left: 50%;
}
#backtotop .menu_item .btn_toolbar:hover {
  opacity: 1;
}
#backtotop .menu_item .btn_toolbar:hover span {
  right: 50px;
  opacity: 1;
}
</style>

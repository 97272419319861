<template>
  <div
    @click="$router.push(link)"
    class="m-7 cursor-pointer flex flex-col justify-center items-center bg-white w-56 h-56 rounded-tl-3xl rounded-br-3xl transition-all duration-300 hover:bg-primary hover:text-white"
  >
    <slot name="icon" /> <i class="fa fa-building"></i>
    <span class="text-xl mt-3 mb-2">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: {
    content: String,
    link: String,
  },
};
</script>

<style></style>

<template>
  <section class="main_section" style="max-width: 1980px">
    <div class="building_method_wrapper">
      <div
        v-animate
        animation="animated animate__animated animate__fadeInLeft"
        class="w-full lg:w-1/2 opacity-0 visible-hidden"
      >
        <BuildingMethodItem
          :bgImage="require('../../assets/images/main/con_box1_back.jpg')"
          :title="'NB브레이스'"
          :description="[
            '특허받은 견고한 접합상세로 기존 구조체와',
            '일체화 거동을 보장하는 비좌굴 가새공법입니다.',
          ]"
          :link="'/board/공법소개/NB브레이스'"
        />
      </div>
      <div
        class="w-full lg:w-1/2 opacity-0 visible-hidden"
        v-animate
        animation="animated animate__animated animate__fadeInRight"
      >
        <BuildingMethodItem
          animationDirection
          :bgImage="require('../../assets/images/main/con_box2_back.jpg')"
          :title="'C - TMD 공법'"
          :description="[
            'X,Y 방향의 질량을 달리하여 2축 방향의 제진이',
            '동시에 수행될 수 있는 동조질량 감쇠 장치 공법입니다.',
          ]"
          :link="'/board/공법소개/C-TMD'"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BuildingMethodItem from "./HomeBuildingMethodItem.vue";
export default {
  components: {
    BuildingMethodItem,
  },
};
</script>

<style scoped>
.building_method_wrapper {
  width: 100%;
  display: flex;
}
@media (max-width: 768px) {
  .building_method_wrapper {
    flex-direction: column;
    width: 100%;
  }
}
</style>

<template>
  <div class="content">
    <article id="post">
      <header>
        <h2 class="post_title">
          <span class="category">{{ postData.division }}</span>
          <span class="text">{{ postData.title }}</span>
        </h2>
      </header>
      <section class="post_info">
        <div class="profile_img">
          <img
            class="rounded-full"
            :src="require('../assets/images/icon/no_profile.gif')"
            alt=""
          />
        </div>
        <div class="flex flex-col flex-wrap px-4">
          <p class="font-bold">{{ postData.title }}</p>
          <div class="text_info">
            <span>댓글 {{ comments.length }}건</span>
            <span>Hit {{ numberFormat(postData.hit) }}회</span>
            <span>Date {{ postData.enrollment_date }}</span>
          </div>
        </div>
      </section>
      <section class="post_content">
        <div v-html="postData.content"></div>
      </section>
      <section v-if="files" class="embeded_file_section">
        <ul class="file_list">
          <li class="file_item">
            <fa-icon class="icon" :icon="['fas', 'folder-open']"></fa-icon>
            <a
              :href="files.path + files.save_name"
              target="_blank"
              class="view_file_download"
              download
            >
              <strong>{{ files.original_name }}</strong> ({{
                Math.ceil((files.size / (1024 * 1024)) * 100) / 100
              }}M)
            </a>
            <br />
            <span class="info">DATE : {{ files.enrollment_date }}</span>
          </li>
        </ul>
      </section>
      <div class="content_bottom">
        <button
          @click="
            $router.push({
              name: 'BoardContent',
              params: {
                ...$route.params,
                postId: null,
              },
            })
          "
        >
          <fa-icon :icon="['fas', 'list']"></fa-icon>
          목록
        </button>
        <!-- <button @click="onClickRepost">
          <fa-icon :icon="['fas', 'reply']"></fa-icon>
          답변
        </button> -->
      </div>
      <ul class="post_list">
        <li class="list_item" v-if="prevPage?.list_pk">
          <span class="move">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
            <fa-icon :icon="['fas', 'chevron-up']"></fa-icon>
            이전글
          </span>
          <a class="cursor-pointer" @click="onClickPageList(prevPage)">{{
            prevPage.title
          }}</a>
          <span class="date">{{ prevPage.enrollment_date.slice(0, 10) }}</span>
        </li>
        <li class="list_item" v-if="nextPage?.list_pk">
          <span class="move">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
            <fa-icon :icon="['fas', 'chevron-down']"></fa-icon>
            다음글
          </span>
          <a class="cursor-pointer" @click="onClickPageList(nextPage)">{{
            nextPage.title
          }}</a>
          <span class="date">{{ nextPage.enrollment_date.slice(0, 10) }}</span>
        </li>
      </ul>
      <section class="section_reply">
        <div class="w-full relative mb-4 underline">
          <button
            class="btn_reply_title"
            @click="isReplyOpened = !isReplyOpened"
          >
            댓글 <span class="num_reply">{{ comments.length }}</span>
            <span class="arrow" :class="isReplyOpened ? 'up' : ''"></span>
          </button>
        </div>
        <div class="w-full" v-if="comments.length === 0 && isReplyOpened">
          <p class="reply_empty">등록된 댓글이 없습니다.</p>
        </div>
        <div v-if="isReplyOpened">
          <div
            v-for="comment in comments"
            :key="comment.comment_key"
            class="w-full mb-4"
            style="border-bottom: 1px solid lightgray"
          >
            <PostComment
              :commentPk="comment.comment_pk"
              :name="comment.name"
              :date="comment.enrollment_date"
              :content="comment.content"
              :secretYn="comment.secret_yn"
              :replyMode="replyMode"
              :addComments="comment.add_comment"
              @change-reply-mode="onChangeReplyMode"
            />
          </div>
        </div>
        <PostCommentForm
          v-if="!replyMode"
          mode="댓글"
          @comment-submit="onSubmitComment"
        />
      </section>
    </article>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

import Board from "../utils/api/Board";
import CommentApi from "../utils/api/Comment";

import { numberFormat } from "@/utils/numberFormat";
import PostComment from "../components/Post/PostComment.vue";
import PostCommentForm from "../components/Post/PostCommentForm.vue";
export default {
  props: {},
  setup() {
    const route = useRoute();
    const postId = computed(() => route.params?.postId);
    const postData = ref({ list_pk: null });
    const comments = ref([]);
    const replyMode = ref(null); // false:일반 댓글, true:대댓글모드
    const files = ref([]);
    const nextPage = ref({});
    const prevPage = ref({});

    const onChangeReplyMode = (commentPk) => {
      replyMode.value = commentPk;
    };
    watchEffect(() => {
      (async function () {
        if (postId.value) {
          const { comment, detail, file, pre, next } = await Board.getDetail(
            postId.value
          );
          postData.value = detail;
          comments.value = comment;
          files.value = file;
          nextPage.value = next;
          prevPage.value = pre;
        }
      })();
    });
    watch(
      postId,
      () => {
        window.scrollTo(0, 0);
      },
      {
        immediate: true,
      }
    );
    const isReplyOpened = ref(true);

    const router = useRouter();

    const onClickRepost = () => {
      if (postData.value.notice_yn === "true") {
        alert("공지에는 답변을 작성할 수 없습니다.");
      } else {
        router.push({
          name: "PostWrite",
          params: { ...route.params, postData: JSON.stringify(postData.value) },
          query: {
            wId: route.params.postId,
          },
        });
      }
    };

    const onSubmitComment = async ({ name, password, content, secret_yn }) => {
      console.log("in post component");

      await CommentApi.saveComment({
        list_pk: postData.value.list_pk,
        name,
        content,
        password,
        secret_yn,
      });

      replyMode.value = null;
    };

    const onClickPageList = (page) => {
      const { menu, list_pk } = page;
      if (menu === "files") {
        router.push({
          name: "Post",
          params: {
            ...route.params,
            postId: list_pk,
          },
        });
      } else if (menu === "photos") {
        router.push(`/board/주요실적/사진/photo/${page.list_pk}`);
      }
    };
    return {
      postData,
      comments,
      files,
      isReplyOpened,
      onSubmitComment,
      numberFormat,
      onClickRepost,
      replyMode,
      onChangeReplyMode,
      nextPage,
      prevPage,
      onClickPageList,
    };
  },
  components: { PostComment, PostCommentForm },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0px auto;
  padding: 30px 15px;
  background: none;
}
#post {
  margin-bottom: 20px;
  background: white;
  box-sizing: border-box;
}
#post header h2 {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  padding: 15px;
  padding-left: 10px;
  background-color: #f7f7f7;
  font-size: 18px;
  display: flex;
  align-items: center;
}
#post header h2 .category {
  display: inline-block;
  margin-right: 10px;
  background: #666;
  color: #fff;
  font-weight: normal !important;
  padding: 0px 8px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}

#post .post_info {
  color: #666;
  margin: 0;
  border-bottom: 1px solid #ecf0f1;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#post .post_info .profile_img {
  width: 50px;
  height: 50px;
  margin-left: 1rem;
}
#post .post_info .text_info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#post .post_info .text_info span {
  padding-right: 1rem;
}

#post .post_content {
  margin: 10px 0 30px;
  width: 100%;
  line-height: 1.7em;
  min-height: 200px;
  word-break: break-all;
  overflow: hidden;
  padding-top: 50px;
}

#post .content_bottom {
  border-top: 1px solid #ecf0f1;
  border-bottom: 0px solid #ddd;
  padding: 1.5rem 0;
  margin: 0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.6rem;
}
#post .content_bottom button {
  position: relative;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #bababa;
  text-align: center;
  padding: 0.3rem 0.5rem;
  transition: all 150ms linear;
}
#post .content_bottom button:hover {
  background: black;
  border: 1px solid black;
  color: white;
}

#post .post_list {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
#post .post_list .list_item {
  border-top: 1px solid #ecf0f1;
  padding: 13px;
  font-size: 13px;
}
#post .post_list .list_item:hover {
  background: #f6f6f6;
}
#post .post_list .list_item:last-child {
  border-bottom: 1px solid #ecf0f1;
}
#post .post_list .list_item .move {
  padding-right: 20px;
  color: #b3b3b3;
}
#post .post_list .list_item .date {
  float: right;
  color: #b3b3b3;
}
.section_reply {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.section_reply .underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2.4rem;
  height: 1px;
  background: #000;
}
.section_reply .btn_reply_title {
  width: 100%;
  text-align: left;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
  font-weight: bold;
  margin: 30px 0 0px;
  padding: 0 0 15px;
}
.section_reply .btn_reply_title .arrow {
  position: absolute;
  right: 0.1rem;
  display: inline-block;
  width: 13px;
  top: 2.5rem;
  height: 8px;
  background: url("../assets/images/icon/btn_cmt.png");
}
.section_reply .btn_reply_title .arrow.up {
  background: url("../assets/images/icon/btn_cmt.png");
  background-position: right -8px;
}

.section_reply .reply_empty {
  margin: 0;
  padding: 80px 0 !important;
  color: #777;
  text-align: center;
}
.section_reply .btn_reply_title .num_reply {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  font-size: 1em;
  color: #3a8afd;
}
.embeded_file_section .file_list {
  margin: 0;
  list-style: none;
}
.embeded_file_section .file_list .file_item {
  padding: 1rem;
  position: relative;
  margin: 10px 0;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 5px 0px hsl(232deg 36% 96%);
  -moz-box-shadow: 1px 1px 5px 0px hsl(232, 36%, 96%);
  box-shadow: 1px 1px 5px 0px hsl(232deg 36% 96%);
}
.embeded_file_section .file_list .file_item:hover {
  border-color: #bed4f4;
  color: #bed4f4;
}
.embeded_file_section .file_list .file_item:focus,
.embeded_file_section .file_list .file_item:hover a,
.embeded_file_section .file_list .file_item a:active,
.embeded_file_section .file_list .file_item:hover .icon {
  text-decoration: underline;
  color: #3a8afd;
}
.embeded_file_section .file_item:hover .info {
  color: #99c2fc;
}

.embeded_file_section .file_list .file_item .icon {
  color: #b2b2b2;
  font-size: 2.2rem;
  margin-right: 20px;
  float: left;
}
.embeded_file_section .file_list .file_item a {
  display: block;
  word-wrap: break-word;
  color: #000;
  float: left;
}
.embeded_file_section .file_list .file_item .info {
  font-size: 0.8rem;
}
</style>

<template>
  <div>
    <div class="w-full justify-center flex gap-1 flex-wrap pt-4 pb-10">
      <div
        @click="onClickYear(year)"
        class="w-36 h-10 mx-2 border flex items-center justify-center text-lg cursor-pointer"
        v-for="year in ['2021', '2020', '2019', '2018', '2017', '2017이전']"
        :key="year"
        style="border: 1px solid #ddd; min-width: 145px"
        :style="
          currentYear === year
            ? 'background: #0F52A1; color: #fff; border: 1px solid #0F52A1;'
            : ''
        "
      >
        {{ year }}
      </div>
    </div>
    <EarningTable
      :year="currentYear"
      :earningItems="earningData.find((v) => v.year === currentYear).tableItem"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import EarningTable from "./EarningTable.vue";

import { earnings } from "../../../constants/earning";

export default {
  setup() {
    const currentYear = ref("2021");
    const onClickYear = (year) => {
      currentYear.value = year;
    };

    const earningData = earnings;
    return {
      currentYear,
      onClickYear,
      earningData,
    };
  },
  components: { EarningTable },
};
</script>

<style scoped></style>

<template>
  <div
    class="flex items-center justify-between mx-auto w-full h-full"
    style="max-width: 1200px"
  >
    <h1 class="nav_logo">
      <router-link to="/">
        <img
          class="nav_logo_img"
          :src="require('../../assets/logo-black.svg')"
          alt="이음로고"
        />
      </router-link>
    </h1>
    <div class="nav_link_wrapper">
      <div class="dropdown_bg"></div>
      <ul class="nav_link_list">
        <li class="nav_link" v-for="menu in menus" :key="menu.title">
          <router-link class="link_title" :to="menu.link"
            >{{ menu.title }}
          </router-link>
          <ul class="nav_dropdown_menu">
            <li
              v-for="submenu in menu.submenus"
              :key="submenu"
              @click="goMenu(menu.title, submenu.title)"
            >
              {{ submenu.title }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const navMenus = [
      {
        title: "About이음",
        children: ["인사말", "사업영역", "CI소개", "연혁"],
      },
      { title: "사업소개", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "공법소개", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "주요실적", children: ["인사말", "사업영역", "CI소개", "연혁"] },
      { title: "고객지원", children: ["인사말", "사업영역", "CI소개", "연혁"] },
    ];

    return {
      navMenus,
      ...useMenus(),
    };
  },
};
const useMenus = () => {
  const store = useStore();
  const router = useRouter();

  const menus = computed(() => store.getters["menus"]);
  const goMenu = (category, subcategory) => {
    router.push(`/board/${category}${subcategory ? "/" + subcategory : ""}`);
  };
  return {
    menus,
    goMenu,
  };
};
</script>

<style scoped>
.nav_logo {
  height: 100%;
  display: flex;
  align-items: center;
}
.nav_logo_img {
  height: 3.6rem;
  width: max-content;
}

.nav_link_list {
  display: flex;
  z-index: 9999;
  background: white;
  position: relative;
  height: 100%;
}

.nav_link_list .nav_link {
  position: relative;
  transition: all 0.4s ease-out;
  border-bottom: 0.1rem solid transparent;
  /* width: 140px; */
  height: 100%;
}

.link_title {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  font-size: 1.2em;
  font-weight: 500;
  height: 100%;
  line-height: 100%;
  text-align: center;

  background-image: linear-gradient(#0f52a1, #0f52a1),
    linear-gradient(white, white);
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 400ms ease-out;
  /* border-bottom: 0px solid rgba(255, 255, 255, 0); */
}
.nav_link_list .nav_link:hover .link_title {
  background-size: 100% 2px, auto;
}
.nav_dropdown_menu {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: all 300ms ease;
  background: white;
  z-index: 100;
}

.nav_link_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav_link_wrapper:hover .nav_dropdown_menu {
  height: 11rem;
}
.dropdown_bg {
  width: 100%;
  height: 0;
  background: white;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  transition: all 300ms ease;
}
.nav_link_wrapper:hover .dropdown_bg {
  height: 13rem;
}
.nav_dropdown_menu li {
  cursor: pointer;
  transition: all 300ms ease-in-out;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
  background: white;
  font-size: 0.9rem;
  margin: 0.1rem 0;
}
.nav_dropdown_menu li:hover {
  background: var(--color-primary);
  color: white;
}
</style>

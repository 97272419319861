<template>
  <div class="board_banner" :class="bgClass">
    <div class="pt-20 pb-5 flex h-full justify-center items-center">
      <h2 class="relative text-white text-4xl font-bold">
        {{ title }}
      </h2>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: {
    title: String,
    bg: Number,
  },
  setup(props) {
    console.log(props.bg);
    const bgClass = computed(() => {
      return `bg${props.bg}`;
    });
    return {
      bgClass,
    };
  },
};
</script>

<style scoped>
.board_banner {
  height: 320px;
}
.bg1 {
  background: url("../../assets/images/sub/sub_visual01.jpg") no-repeat top
    center;
}
.bg2 {
  background: url("../../assets/images/sub/sub_visual02.jpg") no-repeat top
    center;
}
.bg3 {
  background: url("../../assets/images/sub/sub_visual03.jpg") no-repeat top
    center;
}
.bg4 {
  background: url("../../assets/images/sub/sub_visual04.jpg") no-repeat top
    center;
}
.bg5 {
  background: url("../../assets/images/sub/sub_visual05.jpg") no-repeat top
    center;
}
.board_banner h2:after {
  content: "";
  position: absolute;
  top: 3.5rem;
  left: calc(50% - 2rem);
  width: 4rem;
  height: 0.2rem;
  background: white;
}
</style>

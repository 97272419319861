<template>
  <div class="modal_content_wrapper" @click.stop="">
    <h3 class="header">
      비밀번호 입력
      <span
        class="btn_modal_close"
        @click="
          () => {
            $emit('close-modal');
            searchInput = '';
          }
        "
      ></span>
    </h3>
    <form class="form" @submit.prevent="">
      <div class="relative">
        <input v-model="searchInput" type="text" placeholder="비밀번호" />
        <button
          class="w-full px-4 py-2"
          style="background: #222; color: white"
          @click="
            () => {
              $emit('submit-password', searchInput);
              searchInput = '';
            }
          "
        >
          확인
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const searchInput = ref("");

    return {
      searchInput,
    };
  },
};
</script>

<style scoped>
.header {
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6em;
  position: relative;
}
.header .btn_modal_close::after,
.header .btn_modal_close::before {
  content: "";
  position: absolute;
  width: 1.3rem;
  height: 0.15rem;
  border-radius: 5px;
  background: rgb(184, 184, 184);
  cursor: pointer;
}
.header .btn_modal_close::before {
  right: 0.5rem;
  top: 1.5rem;
  transform: rotate(45deg);
}
.header .btn_modal_close::after {
  right: 0.5rem;
  top: 1.5rem;
  transform: rotate(-45deg);
}

.modal_content_wrapper .form {
  display: block;
  width: 100%;
  padding: 1rem;
}
.modal_content_wrapper .form select,
input {
  margin: 0.5rem 0;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 100%;
  text-indent: 5px;
}
.form .search_category {
  display: block;
  width: 100%;
  height: 40px;
  border: 1xp solid #ddd;
  border-radius: 2px;
}
.modal_content_wrapper .form input {
  position: relative;
  padding-right: 40px;
}
.modal_content_wrapper .form input + svg {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}
</style>

<template>
  <div class="w-full py-20 px-4 md:px-20">
    <Outline :headerTitle="' '" :title="`${title} 플로우`" />

    <div
      :class="flows.length <= 4 ? 'cross_line' : 'cross_line_long'"
      style="margin-top: -6rem; position: relative"
    >
      <ul class="flex flex-wrap justify-evenly w-full">
        <li
          class="circle_wrapper"
          :class="flows.length <= 4 ? 'wrapper_mid' : 'wrapper_small'"
          v-for="(item, idx) in flows"
          :key="item"
        >
          <div
            class="circle"
            :class="flows.length <= 4 ? 'circle_mid' : 'circle_small'"
          >
            <span class="text-2xl font-extra-bold text-primary-blue">{{
              `0${idx + 1}`.slice(-2)
            }}</span>

            <p class="font-extra-bold text-lg">{{ item.title }}</p>

            <p
              class="font-medium text-md text-center"
              v-html="item.content"
            ></p>
          </div>

          <div
            class="circle pump_wrap"
            :class="flows.length <= 4 ? 'circle_mid' : 'circle_small'"
          >
            <div
              :class="flows.length <= 4 ? 'pump_outline' : 'pump_outline_small'"
            ></div>

            <div
              :class="flows.length <= 4 ? 'pump_outline' : 'pump_outline_small'"
            ></div>

            <div
              :class="flows.length <= 4 ? 'pump_outline' : 'pump_outline_small'"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";

export default {
  components: { Outline },

  props: { title: String, flows: Array },
};
</script>

<style scoped>
.circle_wrapper {
  position: relative;

  display: flex;

  justify-content: center;

  align-content: center;

  text-align: center;

  z-index: 2;
}

.wrapper_mid {
  width: 250px;

  height: 250px;
}

.wrapper_small {
  width: 180px;

  height: 180px;
}

.circle {
  position: absolute;

  top: 50%;

  background: white;

  z-index: 10;

  border: 1px solid #0f52a1;

  border-radius: 100%;

  color: #1c1c1c;

  display: flex;

  flex-direction: column;

  align-content: center;

  justify-content: center;
}

.circle.pump_wrap {
  z-index: 9;
}

.circle_wrapper .pump_outline,
.circle_wrapper .pump_outline_small {
  z-index: 9;

  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  transform: scale(0);

  border: 2px solid #0f52a1;

  border-radius: 100%;

  animation: pumpOutline 2s 0s linear infinite;
}

.circle_wrapper .pump_outline_small {
  animation-name: pump_outline_small;
}

.circle_wrapper.wrapper_small .pump_outline_small {
  animation-name: pumpOutline;
}

@media (max-width: 1200px) {
  .circle_wrapper .pump_outline_small {
    animation-name: pumpOutline;
  }
}

.circle_wrapper .pump_outline:nth-of-type(1),
.circle_wrapper .pump_outline_small:nth-of-type(1) {
  animation-delay: 0s;
}

.circle_wrapper .pump_outline:nth-of-type(2),
.circle_wrapper .pump_outline_small:nth-of-type(1) {
  animation-delay: -1s;
}

.circle_wrapper .pump_outline:nth-of-type(3),
.circle_wrapper .pump_outline_small:nth-of-type(1) {
  animation-delay: -0.5s;
}

.circle_mid {
  width: 200px;

  height: 200px;

  transform: translateY(-100px);
}

.circle_small {
  width: 160px;

  height: 160px;

  justify-content: flex-start;

  padding: 40px 10px 0 10px;

  transform: translateY(-80px);
}

.cross_line {
  position: relative;

  width: 100%;

  text-align: center;

  margin: 0;
}

.cross_line_long {
  position: relative;

  width: 100%;

  text-align: center;

  margin: 0;
}

.cross_line::before {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 60vw;
  height: 1px;
  margin-left: -600px;
  background: #bbb;
  content: "";
}

.cross_line_long::before {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 70vw;
  min-width: 1000px;
  height: 1px;
  margin-left: -600px;
  background: #bbb;
  content: "";
}

@media (max-width: 1800px) {
  .cross_line::before {
    width: 65vw;
  }
  .cross_line_long::before {
    left: 50%;
  }
}
@media (max-width: 1680px) {
  .cross_line::before {
    width: 65vw;
    left: 60%;
  }
  .cross_line_long::before {
    width: 73vw;
  }
}
@media (max-width: 1270px) {
  .cross_line_long::before {
    width: 0;
    height: 0;
  }
}
@media (max-width: 1200px) {
  .circle_wrapper {
    width: 280px;

    height: 280px;
  }

  .cross_line::before {
    width: 0;

    height: 0;
  }

  .cross_line_long::before {
    width: 0;

    height: 0;
  }
}

@media (max-width: 600px) {
  .circle_wrapper {
    width: 300px;

    height: 280px;
  }

  .cross_line::before {
    min-width: 0;
    top: 10%;
    left: 50%;
    height: 800px;
    width: 1px;
    margin-left: 0px;
    background: #bbb;
    content: "";
  }

  .cross_line_long::before {
    min-width: 0px;
    top: 10%;
    left: 50%;
    width: 1px;
    height: 1400px;
    margin-left: 0px;
    background: #bbb;
    content: "";
  }
}

@keyframes pumpOutline {
  0% {
    -webkit-transform: scale(0.3);

    transform: scale(0.3);

    opacity: 0.3;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1.25);

    transform: scale(1.25);

    opacity: 0;
  }
}

@keyframes pump_outline_small {
  0% {
    -webkit-transform: scale(0.3);

    transform: scale(0.3);

    opacity: 0.3;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1);

    transform: scale(1);

    opacity: 0;
  }
}
</style>

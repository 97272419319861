<template>
  <section class="photo_section">
    <div
      style="visiblility: hidden; opacity: 0"
      v-animate
      animation="animate__animated animate__bounceInUp"
    >
      <p class="section_title">EUM WORK</p>
      <span class="section_text"
        >이음건설산업의
        <br class="block lg:hidden" />
        시공사진입니다</span
      >
    </div>

    <swiper
      v-bind="swiperOptions"
      style="visiblility: hidden; opacity: 0"
      v-animate
      animation="animate__animated animate__fadeInUp"
    >
      <swiper-slide class="" v-for="item in data" :key="item">
        <div class="project_item w-full block">
          <img :src="item.imgSrc" alt="" class="w-full" />

          <div class="text_box">
            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
            </div>
            <p>{{ item.date }}</p>
          </div>
        </div>
      </swiper-slide>
      <div class="absolute bottom-0 w-full py-8">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </swiper>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

import { earningImages } from "@/constants/earning";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const data = earningImages;

    return {
      data,
      ...useSwiper(),
    };
  },
};
const useSwiper = () => {
  const swiperOptions = {
    // 네비게이션
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // 페이지네이션
    pagination: true,

    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      486: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  };
  return { swiperOptions };
};
</script>

<style scoped>
.photo_section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6em 0;
  margin-bottom: 1rem;
  position: relative;
}
.photo_section >>> .swiper-container {
  padding: 2rem 0;
  padding-bottom: 4rem;
}
.photo_section >>> .swiper-pagination {
  bottom: 0px;
  padding: 4rem 0 0 0;
}
.photo_section >>> .swiper-pagination .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  height: 3px;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  .photo_section >>> .swiper-pagination .swiper-pagination-bullet {
    width: 30px;
  }
}
.photo_section >>> .swiper-button-prev,
.photo_section >>> .swiper-button-next {
  position: absolute;
  width: 40px;
  height: 40px;
}
.photo_section >>> .swiper-button-prev::after,
.photo_section >>> .swiper-button-next::after {
  content: "";
  display: block;
}
.photo_section >>> .swiper-button-next,
.photo_section >>> .swiper-button-next::after {
  background: url("../../assets/images/icon/list_next_on2.png") no-repeat;
  right: 0;
  top: 40px px;
}
.photo_section >>> .swiper-button-prev,
.photo_section >>> .swiper-button-prev::after {
  background: url("../../assets/images/icon/list_prev_on2.png") no-repeat;
  left: 0;
  top: 40px px;
}

.photo_section >>> .text_box {
  width: 100%;
  height: 280px;
  text-align: left;
  background: #f7f7f7;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.photo_section >>> .text_box h3 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1rem;
}
</style>

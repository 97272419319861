<template>
  <div>
    <GeneralOutline :title="outlineTitle" :content="outlineContent" />
    <BusinessCharacteristic
      :sectionTitle="'내진성능평가 특징'"
      :innerTitle="characteristicTitle"
      :content="characteristicContent"
      :wrapperStyle="characteristicWrapperStyle"
    />
    <BusinessTable title="내진성능평가 플로우">
      <template v-slot:table-list>
        <ul style="display: flex; flex-direction: column; gap: 1rem">
          <BusinessFlowTableItem
            v-for="(item, idx) in flows"
            :key="item"
            :index="('0' + (idx + 1)).slice(0, 2)"
            :content="item"
          />
        </ul>
      </template>
    </BusinessTable>
    <BusinessTable title="내진성능 수준">
      <template v-slot:table-list>
        <table class="w-full mx-auto">
          <colgroup>
            <col style="width: 40%" />
            <col style="width: 60%" />
          </colgroup>
          <thead>
            <tr>
              <th class="table_header">성능수준</th>
              <th class="table_header">피해</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in level" :key="item">
              <td class="table_item">{{ item.title }}</td>
              <td class="table_item">
                {{ item.content }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </BusinessTable>
    <BusinessTable title="내진성능 목표">
      <template v-slot:table-list>
        <div class="w-full py-10">
          <table class="w-full mx-auto">
            <colgroup>
              <col style="width: 30%" />
              <col style="width: 30%" />
              <col style="width: 30%" />
            </colgroup>
            <thead>
              <tr>
                <th class="table_header">건축물의 성능수준</th>
                <th class="table_header">건축물의 성능수준</th>
                <th class="table_header">비구조요소의 성능수준</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in goal" :key="item">
                <td class="table_item">{{ item[1] }}</td>
                <td class="table_item">
                  {{ item[2] }}
                </td>
                <td class="table_item">
                  {{ item[3] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full py-10">
          <table class="w-full mx-auto">
            <colgroup>
              <col style="width: 30%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 30%" />
            </colgroup>
            <thead>
              <tr>
                <th class="table_header" rowspan="2">내진등급</th>
                <th class="table_header" colspan="2">성능목표</th>
                <th class="table_header" rowspan="2">설계지진</th>
              </tr>
              <tr>
                <th class="table_header">재현주기</th>
                <th class="table_header">성능수준</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in goal2" :key="item">
                <td v-if="idx % 2 === 0" class="table_item" rowspan="2">
                  {{ item.level }}
                </td>
                <td class="table_item">{{ item.cycle }}</td>
                <td class="table_item">{{ item.performance }}</td>
                <td class="table_item">{{ item.detail }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </BusinessTable>
  </div>
</template>

<script>
import GeneralOutline from "../../Outline.vue";

import { business2 } from "@/constants/businessIntroduction";
import BusinessCharacteristic from "./BusinessCharacteristic.vue";
import BusinessTable from "./BusinessFlowTable.vue";
import BusinessFlowTableItem from "./BusinessFlowTableItem.vue";

export default {
  setup() {
    const outlineTitle = business2.outline.title;
    const outlineContent = business2.outline.content;
    const characteristicTitle = business2.characteristic.title;
    const characteristicContent = business2.characteristic.content;
    const characteristicWrapperStyle = business2.characteristic?.wrapperStyle;

    const flows = business2.flows;

    const level = business2.level;
    const goal = business2.goal;
    const goal2 = business2.goal2;
    return {
      outlineTitle,
      outlineContent,
      characteristicTitle,
      characteristicContent,
      characteristicWrapperStyle,
      flows,
      level,
      goal,
      goal2,
    };
  },
  components: {
    GeneralOutline,
    BusinessCharacteristic,
    BusinessTable,
    BusinessFlowTableItem,
  },
};
</script>

<style scoped>
th,
td {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.6em;
  font-family: "NanumSquare", sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: center;
  border: 1px solid #ddd;
}
.table_header {
  background: #1e3c66;
  font-size: 18px;
  font-weight: 500px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
}
.table_item {
  padding: 20px;
  background: white;
}
</style>

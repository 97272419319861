<template>
  <div class="relative flex gap-5 flex-wrap">
    <div>
      <img
        class="rounded-full"
        style="witdh: 50px; height: 50px"
        :src="
          replyData?.profile ??
          require('../../assets/images/icon/no_profile.gif')
        "
        alt="프로필 사진 없음"
      />
    </div>
    <div class="mx-full pr-5 flex-1 relative" style="max-width: 90%">
      <p class="relative w-full flex gap-5 pb-1 whitespace-nowrap">
        <span class="font-bold">{{ replyData.name }}</span>
        <!-- <span class="text-gray">작성일 {{ date }}</span> -->
      </p>
      <p
        v-if="replyData.secret_yn == 'false'"
        class="pb-1"
        style="min-height: 50px"
      >
        {{ replyData.content }}
      </p>
      <p v-else class="pb-1" style="min-height: 50px">
        {{ secretContent ? secretContent.content : "비밀 댓글입니다." }}
        <span
          v-if="secretContent === ''"
          class="px-2 cursor-pointer hover:text-gray"
          @click="onClickShowSecretComment"
          >보기</span
        >
      </p>
    </div>
    <div class="absolute top-0 right-0">
      <button
        ref="dropdownRef"
        class="text-xl text-gray hover:text-black relative"
        @click.stop="openDropdown"
      >
        <fa-icon :icon="['fas', 'ellipsis-v']" />
        <ul class="comment_dropdown" v-if="isDropdownOpen" @click.stop="">
          <li @click="setModal(true)">
            삭제
            <fa-icon :icon="['fas', 'trash-alt']"></fa-icon>
          </li>
          <li @click="isDropdownOpen = false">
            닫기
            <fa-icon :icon="['fas', 'times']"></fa-icon>
          </li>
        </ul>
      </button>
    </div>
    <transition name="fade">
      <div v-show="pwModal" class="bg_modal" @click="setModal(false)">
        <PasswordModal
          @close-modal="setModal(false)"
          @submit-password="onClickDeleteReply"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-show="secretModal" class="bg_modal" @click="setSecretModal(false)">
        <PasswordModal
          @close-modal="setSecretModal(false)"
          @submit-password="showSecretComment"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue-demi";
import { useCommentDropDown } from "./PostComment.vue";
import CommentApi from "../../utils/api/Comment";
import PasswordModal from "../PasswordModal.vue";
export default {
  props: {
    replyData: Object,
  },
  setup(props) {
    const pwModal = ref(false);
    const setModal = (value) => (pwModal.value = value);
    const onClickDeleteReply = async (password) => {
      await CommentApi.deleteReplyComment({
        add_comment_pk: props.replyData.add_comment_pk,
        password,
      });
    };

    const secretContent = ref("");
    const secretModal = ref(false);
    const setSecretModal = (value) => (secretModal.value = value);
    const onClickShowSecretComment = () => {
      setSecretModal(true);
    };

    const showSecretComment = async (password) => {
      secretContent.value = await CommentApi.getAddSecretComment({
        add_comment_pk: props.replyData.add_comment_pk,
        password,
      });
      setSecretModal(false);
    };
    return {
      ...useCommentDropDown(),
      pwModal,
      setModal,
      onClickDeleteReply,
      secretContent,
      secretModal,
      setSecretModal,
      onClickShowSecretComment,
      showSecretComment,
    };
  },
  components: { PasswordModal },
};
</script>

<style scoped>
.comment_dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  width: 70px;
  text-align: left;
  border: 1px solid #b8bfc4;
  margin: 0;
  list-style: none;
  background: #fff;
  zoom: 1;
  z-index: 9999;
}
.comment_dropdown::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #b8bfc4 transparent;
}
.comment_dropdown li {
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0.5rem 0;

  padding: 10px 10px;
  color: #6b757c;
  text-align: left;
}
.comment_dropdown li:hover {
  color: #3a8afd;
}
</style>

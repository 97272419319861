<template>
  <div class="pt-6 w-full max-w-4xl mx-auto">
    <form @submit="onSubmit">
      <section class="pb-2 px-4">
        <select name="" class="w-full border mb-2" v-model="category">
          <option value="카다로그">카다로그</option>
          <option value="CI">CI</option>
          <option value="시방서 및 특허">시방서 및 특허</option>
          <option value="사업자등록증">사업자등록증</option>
          <option value="기타">기타</option>
        </select>
        <div class="w-full flex gap-2">
          <div class="flex flex-col w-1/2">
            <input
              class="w-full border rounded-md required"
              :class="nameErrorMsg ? 'warning' : ''"
              type="text"
              name="name"
              id="edit_name"
              placeholder="이름"
              v-model="name"
            />
            <span class="err_msg">{{ nameErrorMsg }}</span>
          </div>
          <div class="flex flex-col w-1/2">
            <input
              class="w-full border rounded-md required"
              :class="passwordErrorMsg ? 'warning' : ''"
              type="password"
              name="password"
              id="edit_password"
              placeholder="비밀번호"
              v-model="password"
            />
            <span class="err_msg">{{ passwordErrorMsg }}</span>
          </div>
        </div>

        <div class="w-full flex gap-2">
          <div class="flex flex-col w-1/2">
            <input
              class="w-full border rounded-md"
              id="edit_email"
              type="text"
              placeholder="이메일"
              v-model="email"
              name="email"
            />
            <span class="err_msg">{{ emailErrorMsg }}</span>
          </div>
          <div class="flex flex-col w-1/2">
            <input
              class="w-full border rounded-md"
              type="text"
              id="edit_homepage"
              placeholder="홈페이지"
              v-model="homepage"
              name="homepage"
            />
            <span class="err_msg">{{ homepageErrorMsg }}</span>
          </div>
        </div>
        <div class="w-full">
          <input
            class="border rounded-md w-full required"
            :class="titleErrorMsg ? 'warning' : ''"
            type="text"
            id="edit_title"
            placeholder="제목"
            v-model="title"
            name="title"
          />
          <span class="err_msg">{{ titleErrorMsg }}</span>
        </div>
      </section>
      <section class="px-4">
        <div class="w-full py-2 flex justify-end">
          <!-- <button class="border rounded-md px-2">단축키 알람</button> -->
        </div>

        <div ref="toastEditorRef"></div>
        <div>
          <input
            class="w-full border my-2"
            type="text"
            placeholder="파일 주소"
          />
          <input
            class="w-full border my-2"
            type="text"
            placeholder="파일 주소"
          />
          <input
            class="w-full border my-2"
            type="file"
            placeholder=""
            @change="($event) => onChangeFile($event, 1)"
          />
          <input
            class="w-full border my-2"
            type="file"
            placeholder=""
            @change="($event) => onChangeFile($event, 2)"
          />
        </div>
      </section>
      <section class="p-4 pb-2">
        <div class="flex flex-col flex-wrap">
          <div class="flex gap-2 pb-4">
            <div class="flex flex-col gap-1">
              <input
                class="required"
                :class="writerErrorMsg ? 'warning' : ''"
                type="text"
                placeholder="이름"
                size="25"
                v-model="writer"
              />
              <span style="color: red">{{ writerErrorMsg }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <input
                class="required"
                :class="postPwErrorMsg ? 'warning' : ''"
                type="password"
                placeholder="비밀번호"
                size="25"
                v-model="postPw"
              />
              <span style="color: red">{{ postPwErrorMsg }}</span>
            </div>
          </div>
        </div>
        <div class="w-full relative flex justify-center items-center py-4">
          <!-- <div class="absolute left-0 top-4">
          <input type="checkbox" name="" id="secret_reply" />
          <label for="secret_reply" class="hover:text-primary-blue"
            >비밀글</label
          >
        </div> -->
        </div>
      </section>
      <div class="flex justify-center pb-5">
        <button
          class="border mx-1 px-4 py-2 text-white rounded-md bg-gray hover:bg-lightgray w-24 transition-all duration-200"
        >
          취소
        </button>
        <button
          class="border mx-1 px-4 py-2 bg-black text-white rounded-md hover:bg-primary-blue w-24"
          type="submit"
          @click="onSubmit"
        >
          작성완료
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

import "@toast-ui/editor/dist/toastui-editor.css"; // Editor's Style
import Editor from "@toast-ui/editor";

import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Post from "../utils/api/Post";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    onBeforeMount(() => {
      if (!route.query?.wId) {
        alert("다시 시도해주세요.");
        router.push("/board/고객지원/자료실");
      }
    });

    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    return { ...useFormValue() };
  },
};

const useFormValue = () => {
  const { toastEditorRef, getEditorValue } = useEditor();

  const { handleSubmit, resetForm } = useForm();
  const { value: name, errorMessage: nameErrorMsg } = useField(
    "name",
    yup.string().required("이름을 입력해주세요")
  );
  const { value: password, errorMessage: passwordErrorMsg } = useField(
    "password",
    yup.string().required("비밀번호를 입력해주세요")
  );
  const { value: email, errorMessage: emailErrorMsg } = useField(
    "email",
    yup.string().email("이메일 형식을 지켜주세요")
  );
  const { value: homepage, errorMessage: homepageErrorMsg } = useField(
    "homepage",
    yup.string()
  );
  const { value: title, errorMessage: titleErrorMsg } = useField(
    "title",
    yup.string().required("제목을 입력해주세요")
  );

  const { value: writer, errorMessage: writerErrorMsg } = useField(
    "writer",
    yup.string().required("이름을 입력해주세요")
  );
  const { value: postPw, errorMessage: postPwErrorMsg } = useField(
    "postPw",
    yup.string().required("비밀번호를 입력해주세요")
  );

  const postData = ref({});
  const category = ref("");
  const files = [null, null];

  const onChangeFile = (e, idx) => {
    files[idx] = e.target.files[0];
    console.log(files[idx]);
  };
  const route = useRoute();
  const router = useRouter();

  onMounted(() => {
    if (route.params?.postData) {
      postData.value = JSON.parse(route.params?.postData);
      title.value = "RE: " + postData.value.title;
      category.value = postData.value.division;
    } else {
      router.push("/board/고객지원/자료실");
    }
  });
  const onInvalid = ({ values, errors, results }) => {
    console.log("invalid submit");
    console.log(values);
    console.log(errors);
    console.log(results);
  };
  const onSubmit = handleSubmit((values) => {
    const editorValue = getEditorValue();
    console.log("successs submit");
    console.log(editorValue);
    if (editorValue.trim().length === 0) {
      return;
    }
    console.log(values);
    Post.saveList({
      menu: postData.value.menu,
      list_pk: postData.value.list_pk,
      title: title.value,
      content: editorValue,
    });
    resetForm();
  }, onInvalid);

  return {
    // formInputValues,
    category,
    onSubmit,
    onChangeFile,
    toastEditorRef,
    name,
    nameErrorMsg,
    password,
    passwordErrorMsg,
    email,
    emailErrorMsg,
    title,
    titleErrorMsg,
    homepage,
    homepageErrorMsg,
    writer,
    writerErrorMsg,
    postPw,
    postPwErrorMsg,
  };
};

const useEditor = () => {
  const toastEditorRef = ref(null);
  const editor = ref(null);
  onMounted(() => {
    editor.value = new Editor({
      el: toastEditorRef.value,
      height: "500px",
      initialEditType: "wysiwyg",
      previewStyle: "tab",
    });
  });

  const getEditorHTML = () => editor.value.getHTML();
  const getEditorMarkdown = () => editor.value.getMarkdown();

  const getEditorValue = () => {
    let value = "";
    if (editor.value.isWysiwygMode()) {
      value = getEditorHTML();
    } else {
      value = getEditorMarkdown();
    }

    return value;
  };
  return {
    toastEditorRef,
    getEditorValue,
  };
};
</script>

<style scoped>
input,
textarea,
select {
  height: 40px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding-left: 12px;
}
textarea {
  height: 300px;
}

.warning {
  border: 1px solid red;
}
.err_msg {
  color: red;
  padding: 0.2rem 0.1rem;
}
</style>

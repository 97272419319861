<template>
  <div class="home">
    <HomeBannerSlide />

    <HomeBusinessSection />

    <HomeMethodSection />

    <HomePhotoSection />
    <HomeCompanySection />
  </div>
</template>

<script>
// @ is an alias to /src

import HomeBannerSlide from "@/components/HomeSection/HomeBannerSlide.vue";
import HomeBusinessSection from "@/components/HomeSection/HomeBusinessSection.vue";
import HomeMethodSection from "@/components/HomeSection/HomeMethodSection.vue";
import HomePhotoSection from "@/components/HomeSection/HomePhotoSection.vue";
import HomeCompanySection from "@/components/HomeSection/HomeCompanySection.vue";

export default {
  name: "Home",
  components: {
    HomeBannerSlide,
    HomeBusinessSection,
    HomeMethodSection,
    HomePhotoSection,
    HomeCompanySection,
  },
};
</script>

<style>
.main_section {
  width: 100%;
  /* max-width: 1500px; */
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 10rem;
  /* padding: 1rem 0; */
}

.content_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.section_title {
  text-align: center;
  font-size: 2.5em;
  line-height: 50px;
  color: #000;
  font-weight: 700;
  padding: 0 10px;
  margin: 0px auto;
}
.section_text {
  display: block;
  text-align: center;
  font-size: 1.15em;
  color: #777;
  padding: 0 10px;
  margin: 10px 0 20px 0;
}
</style>

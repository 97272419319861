<template>
  <div class="w-full px-3">
    <div class="text-center pb-24 flex flex-col items-center">
      <h3 class="text-lg md:text-xl mb-5">{{ headerTitle }}</h3>
      <h2 class="text-2xl md:text-3xl mb-2">
        {{ title }}
      </h2>
      <h4 class="mb-3">{{ subTitle }}</h4>
      <div class="w-10 h-0.5 bg-black"></div>
      <p
        class="content mt-10 font-light leading-8 tracking-tighter"
        v-html="content"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: "A GENERAL OUTLINE",
    },
    title: String,
    subTitle: String,
    content: String,
  },
};
</script>

<style scoped>
.content {
  font-size: 17px;
}
:deep(p > u) {
  display: block;
}
@media screen and (max-width: 768px) {
  :deep(p > u) {
    display: inline;
  }
}
</style>

import router from "../../router";
import axios from "./index";

const CommentApi = {
  async saveComment({ list_pk, name, content, secret_yn, password }) {
    await axios
      .post("/saveComment", {
        list_pk,
        name,
        content,
        secret_yn: secret_yn === true ? "true" : "false",
        password,
      })
      .catch(() =>
        alert("댓글 작성중 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.")
      )
      .finally(() => {
        router.go(0);
      });
  },
  async addRecomment({ comment_pk, name, content, password, secret_yn }) {
    await axios
      .post("/saveAddComment", {
        comment_pk,
        name,
        content,
        password,
        secret_yn: secret_yn === true ? "true" : "false",
      })
      .catch(() =>
        alert("댓글 작성 중 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.")
      )
      .finally(() => router.go(0));
  },
  async deleteComment({ comment_pk, password }) {
    await axios
      .post("/deleteComment", { comment_pk, password })
      .then((res) => {
        if (res.data.code === 99116) {
          alert("비밀번호가 일치하지 않습니다.");
        }
      })
      .catch(() =>
        alert("댓글 삭제 중 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.")
      )
      .finally(() => router.go(0));
  },
  async deleteReplyComment({ add_comment_pk, password }) {
    await axios
      .post("/deleteAddComment", {
        add_comment_pk,
        password,
      })
      .then((res) => {
        if (res.data.code === 99116) {
          alert("비밀번호가 일치하지 않습니다.");
        }
      })
      .catch(() =>
        alert("댓글 삭제 중 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.")
      )
      .finally(() => router.go(0));
  },
  async getSecretComment({ comment_pk, password }) {
    return await axios
      .get("/getComment", {
        params: {
          comment_pk,
          password,
        },
      })
      .then((res) => res.data.commnet)
      .catch(() =>
        alert("데이터를 불러오는데 실패하였습니다. 고객센터에 문의해주세요.")
      );
  },
  async getAddSecretComment({ add_comment_pk, password }) {
    return await axios
      .get("/getAddComment", {
        params: {
          add_comment_pk,
          password,
        },
      })
      .then((res) => res.data.commnet)
      .catch(() =>
        alert("데이터를 불러오는데 실패하였습니다. 고객센터에 문의해주세요.")
      );
  },
};
export default CommentApi;

<template>
  <div id="greeting">
    <img
      class="w-full"
      :src="require('../../../assets/images/board/0101.jpg')"
      alt="인사말 사진"
    />
  </div>
</template>

<script>
export default {
  name: "Greeting",
};
</script>

<style scoped>
#greeting {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}
</style>

<template>
  <div class="mx-auto px-2" style="width: 100%; max-width: 1200px">
    <section id="kakao_map">
      <KakaoMap />
    </section>
    <section class="map_info">
      <h3 class="`w-full text-xl pb-2`">오시는 길</h3>
      <div class="w-full">
        <table class="w-full border-t">
          <tbody>
            <tr>
              <th>주소</th>
              <td>{{ mapInfo.address }}</td>
            </tr>
            <tr>
              <th>전화</th>
              <td>{{ mapInfo.contact }}</td>
            </tr>
            <tr>
              <th>팩스</th>
              <td>{{ mapInfo.fax }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <a :href="`mailto: ${mapInfo.email}`"> {{ mapInfo.email }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

import KakaoMap from "../../KakaoMap.vue";

export default {
  components: {
    KakaoMap,
  },
  setup() {
    const mapInfo = ref({
      address: "서울시 동대문구 고미술로 81 평화빌딩 309호",
      contact: "02)2138-3080 / 02)2088-2791",
      fax: "요청",
      email: "eum@eumcon.com",
    });
    return {
      mapInfo,
    };
  },
};
</script>

<style scoped>
.map_info {
  width: 100%;
  margin-top: 40px;
  padding: 2rem 0;
}
.map_info tr {
  padding: 0;
  margin: 0;
  width: 20%;
  text-align: center;
  font-size: 1em;
  color: #000;
  vertical-align: middle;
  font-weight: normal;
  border-bottom: 1px solid white;
}
.map_info th {
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.6em;
  font-family: "NanumSquare", sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
}
.map_info td {
  width: 80%;
  text-align: left;
  font-size: 1em;
  color: #888;
  vertical-align: top;
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #eee;
  background: #fff;
}
</style>

<template>
  <div
    class="w-full py-20 px-4 mx-auto md:px-0 transition-all duration-200"
    style="max-width: 1200px"
  >
    <Outline :headerTitle="' '" :title="`${title}`" />
    <div class="w-full mx-auto" style="margin-top: -5rem">
      <slot name="table-list"> </slot>
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
export default {
  props: {
    title: String,
  },
  components: {
    Outline,
  },
};
</script>

<style></style>

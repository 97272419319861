<template>
  <div>
    <div class="flex flex-wrap gap-3 relative">
      <div>
        <img
          class="rounded-full"
          style="witdh: 50px; height: 50px"
          :src="profile ?? require('../../assets/images/icon/no_profile.gif')"
          alt="프로필 사진 없음"
        />
      </div>
      <div class="mx-full pr-5 flex-1" style="max-width: 90%">
        <p class="relative w-full flex gap-5 pb-1 whitespace-nowrap">
          <span class="font-bold">{{ name }}</span>
          <span class="text-gray">작성일 {{ date }}</span>
        </p>
        <p v-if="secretYn == 'false'" class="pb-1" style="min-height: 50px">
          {{ content }}
        </p>
        <p v-else class="pb-1" style="min-height: 50px">
          {{
            secretContent === "" ? "비밀 댓글입니다." : secretContent.content
          }}
          <span
            v-if="secretContent === ''"
            class="px-2 cursor-pointer hover:text-gray"
            @click="onClickShowSecretComment"
            >보기</span
          >
        </p>
      </div>
      <div class="absolute top-0 right-0">
        <button
          ref="dropdownRef"
          class="text-xl text-gray hover:text-black relative"
          @click.stop="openDropdown"
        >
          <fa-icon :icon="['fas', 'ellipsis-v']" />
          <ul class="comment_dropdown" v-if="isDropdownOpen" @click.stop="">
            <li @click="onClickReply">
              답변
              <fa-icon :icon="['fas', 'reply']"></fa-icon>
            </li>
            <li @click="onClickDelete">
              삭제
              <fa-icon :icon="['fas', 'trash-alt']"></fa-icon>
            </li>
            <li @click="isDropdownOpen = false">
              닫기
              <fa-icon :icon="['fas', 'times']"></fa-icon>
            </li>
          </ul>
        </button>
      </div>
    </div>
    <div class="pl-16 relative" v-if="addComments.length > 0">
      <PostReplyComment
        v-for="replyComment in addComments"
        :key="replyComment.add_comment_pk"
        :replyData="replyComment"
      />
    </div>
    <div v-if="replyMode === commentPk" class="pl-14">
      <PostCommentForm mode="대댓글" @comment-submit="onSubmitComment" />
    </div>
    <transition name="fade">
      <div v-show="pwModal" class="bg_modal" @click="setModal(false)">
        <PasswordModal
          @close-modal="setModal(false)"
          @submit-password="onDeleteComment"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-show="secretModal" class="bg_modal" @click="setSecretModal(false)">
        <PasswordModal
          @close-modal="setSecretModal(false)"
          @submit-password="showSecretComment"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onClickOutside } from "@vueuse/core";
import CommentApi from "@/utils/api/Comment.js";
import PostCommentForm from "./PostCommentForm.vue";
import PostReplyComment from "./PostReplyComment.vue";
import PasswordModal from "../PasswordModal.vue";
export default {
  props: {
    commentPk: Number,
    profile: String,
    name: String,
    date: String,
    content: String,
    secretYn: String,
    replyMode: Number || null,
    addComments: Array,
  },
  setup(props, { emit }) {
    const { dropdownRef, isDropdownOpen, openDropdown, deleteComment } =
      useCommentDropDown(props.commentPk);

    const onClickReply = () => {
      emit("change-reply-mode", props.commentPk);
      isDropdownOpen.value = false;
    };

    const onSubmitComment = async ({ name, password, content, secret_yn }) => {
      await CommentApi.addRecomment({
        comment_pk: props.commentPk,
        name,
        password,
        content,
        secret_yn,
      });
    };

    const onClickDelete = () => {
      setModal(true);
    };
    const onDeleteComment = (password) => {
      setModal(false);
      deleteComment(props.commentPk, password);
    };

    const secretContent = ref("");
    const secretModal = ref(false);
    const setSecretModal = (value) => (secretModal.value = value);
    const onClickShowSecretComment = () => {
      setSecretModal(true);
    };

    const showSecretComment = async (password) => {
      secretContent.value = await CommentApi.getSecretComment({
        comment_pk: props.commentPk,
        password,
      });
      setSecretModal(false);
    };
    const pwModal = ref(false);
    const setModal = (value) => {
      pwModal.value = value;
    };
    return {
      pwModal,
      setModal,
      dropdownRef,
      onClickShowSecretComment,
      showSecretComment,
      secretModal,
      secretContent,
      setSecretModal,
      isDropdownOpen,
      openDropdown,
      deleteComment,
      onClickReply,
      onSubmitComment,
      onClickDelete,
      onDeleteComment,
    };
  },
  components: { PostCommentForm, PostReplyComment, PasswordModal },
};
export const useCommentDropDown = () => {
  const isDropdownOpen = ref(false);
  const dropdownRef = ref(null);
  onClickOutside(dropdownRef, () => {
    isDropdownOpen.value = false;
  });
  const openDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
  };
  const deleteComment = async (comment_pk, password) => {
    CommentApi.deleteComment({ comment_pk, password });
  };
  return {
    dropdownRef,
    isDropdownOpen,
    openDropdown,
    deleteComment,
  };
};
</script>

<style scoped>
.comment_dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  width: 70px;
  text-align: left;
  border: 1px solid #b8bfc4;
  margin: 0;
  list-style: none;
  background: #fff;
  zoom: 1;
  z-index: 9999;
}
.comment_dropdown::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #b8bfc4 transparent;
}
.comment_dropdown li {
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0.5rem 0;

  padding: 10px 10px;
  color: #6b757c;
  text-align: left;
}
.comment_dropdown li:hover {
  color: #3a8afd;
}
</style>

import axios from "axios";
const instance = axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_URL,
  timout: 5000,
});

instance.interceptors.request.use(
  (config) => {
    // 요청 전 axios 설정
    config.headers.common["channel-code"] = process.env.VUE_APP_CHANNEL_CODE;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    console.log(response.config.url, response.data);
    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export default instance;

<template>
  <section class="main_section" id="business_section">
    <div
      class="title_wrapper"
      v-animate
      style="visiblility: hidden; opacity: 0"
      animation="animate__animated animate__bounceInUp"
    >
      <p class="section_title">OUR BUSINESS</p>
      <span class="section_text">이음건설산업의 사업분야를 소개합니다.</span>
    </div>
    <div class="business_area">
      <ul class="business_item_wrapper">
        <BusinessAreaItem
          v-for="(title, idx) in [
            '신축구조설계',
            '내진성능평가',
            '구조물 안전진단',
            '내진보강공사',
            '공법개발',
          ]"
          :key="idx"
          :title="title"
          :index="idx + 1"
        />
      </ul>
    </div>
  </section>
</template>

<script>
import BusinessAreaItem from "./HomeBusinessAreaItem.vue";

export default {
  components: {
    BusinessAreaItem,
  },
};
</script>

<style scoped>
#business_section {
  background: url("../../assets/images/main/business_back.jpg") no-repeat;
  background-position: top center;
  margin: 0 auto;
  margin-top: 10rem;
}
.title_wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-bottom: 50px;
  /* opacity: 0; */
}
.business_area {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}
.business_area .business_item_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#business_section
  .business_area
  .business_item_wrapper
  >>> .business_area_item {
  width: 18%;
}

@media (max-width: 768px) {
  #business_section {
    margin-top: 90px;
  }
  #business_section .business_area {
    width: 90%;
    margin: 0 auto;
  }
  #business_section .business_area .business_item_wrapper {
    flex-direction: column;
  }
  #business_section
    .business_area
    .business_item_wrapper
    >>> .business_area_item {
    width: 100%;
  }
}
</style>

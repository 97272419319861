<template>
  <footer id="footer" class="px-4">
    <div id="info">
      <p>
        <span>(주)이음건설산업</span>
        <span>대표 : 이호경</span>
        <span>주소 : 서울시 광진구 능동로 245, DH빌딩 403호</span>
        <span>사업자등록번호 : 395-88-00774</span>
        <span>TEL : 02)2138-3080</span>
        <span>FAX : 02)2088-2791</span>

        <span>개인정보책임관리자 : 이호경</span>
        <span>이메일 : eum@eumcon.com</span>
        <span>Copyright 202104 이음 All Rights Reserved.</span>
      </p>
    </div>
    <div id="help" class="border flex justify-between p-5">
      <div class="help_center flex flex-col text-left">
        <span class="py-0.5 text-lg">고객센터</span>
        <span class="py-0.5 block border-t border-white h-1 w-4/5"></span>
        <a href="tel:02231383080" class="py-0.5 text-white">
          <span class="block text-xl">02-2138-3080</span>
        </a>
      </div>
      <div class="time_info flex flex-col py-2">
        <span class="text-xs">평일 09:00 - 18:00</span>
        <span class="text-xs">점심 11:30 - 13:00</span>
        <span class="text-xs">(주말, 공휴일 휴무)</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
#footer {
  width: 100%;
  padding: 30px 0;
  background: #333;
  display: flex;
}
#footer div {
  color: white;
}
#info {
  width: 70%;
  padding: 30px 10px 0 20px;
}
#info p {
  width: 100%;
  color: #888;
  text-align: left;
}
#info p span {
  margin-right: 15px;
  display: inline-block;
}
#help {
  font-weight: 500;
  width: 320px;
  min-width: 320px;
  border: 1px solid #555;
}
#help .time_info {
  color: #888;
}
#help .help_center {
  width: 60%;
  color: white;
}

@media (max-width: 1100px) {
  #footer {
    min-width: 320px;
    padding-bottom: 30px;
    text-align: center;
  }
  #info {
    width: 100%;
    padding: 0 30px;
  }
  #info p {
    text-align: center;
  }
  #help {
    display: none;
  }
}
</style>

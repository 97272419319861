<template>
  <div>
    <div class="mb-24">
      <div class="flex justify-center flex-wrap">
        <div
          v-for="tab in tabs"
          :key="tab"
          class="business_item"
          :style="
            currentTab === tab.title
              ? 'background:rgb(15, 82, 161);color:white;'
              : ''
          "
          @click="initTab(tab.title)"
        >
          <fa-icon :icon="tab.icon"></fa-icon>
          <span class="tab_content">{{ tab.title }}</span>
        </div>
      </div>
    </div>
    <GeneralOutline :title="currentTab" :content="tabContent?.content" />
  </div>
</template>

<script>
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import router from "../../../router";

import { tabContents } from "@/constants/aboutBusiness.js";
import GeneralOutline from "../../Outline.vue";

export default {
  setup() {
    const route = useRoute();
    const tabs = [
      { title: "신축구조설계", icon: ["fas", "building"] },
      { title: "내진성능평가", icon: ["fas", "edit"] },
      { title: "구조물안전진단", icon: ["fas", "exclamation-triangle"] },
      { title: "내진보강공사", icon: ["fas", "hammer"] },
      { title: "공법개발", icon: ["fas", "search-plus"] },
    ];
    const currentTab = computed(() => {
      const tab = route.query?.tab;
      if (!tab) return;
      return tab;
    });
    const tabContent = computed(() => {
      return tabContents.find((content) => content.title === currentTab.value);
    });
    const initTab = (tab) => {
      router.push({
        name: "BoardContent",
        params: { ...route.params },
        query: {
          tab,
        },
      });
    };
    onBeforeMount(() => {
      if (!route.query?.tab) {
        initTab("신축구조설계");
      }
    });
    return {
      tabs,
      currentTab,
      tabContent,
      initTab,
    };
  },
  components: { GeneralOutline },
};
</script>

<style scoped>
.business_item {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: all 200ms ease;
  cursor: pointer;
  border: 1px solid #7b9abd;
}
.business_item:hover {
  background: rgb(15, 82, 161);
  color: white;
}
.business_item .tab_content {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.3rem;
}
.business_item svg {
  font-size: 3rem;
}
</style>

import axios from "./index";

export default {
  async saveQustion({ menu, title, name, contact, email, agree }) {
    await axios
      .post("/saveQuestion", {
        menu,
        title,
        name,
        contact,
        email,
        agree: agree ? "true" : "false",
      })
      .catch(() =>
        alert("문의 등록 중 문제가 발생하였습니다. 잠시 후 다시 시도해주세요.")
      );
  },
  async saveList({
    menu,
    type,
    list_pk,
    title,
    division,
    content,
    notice_yn,
    state,
    file,
  }) {
    await axios
      .post("/saveList", {
        menu,
        type,
        list_pk,
        title,
        division,
        content,
        notice_yn,
        state,
        file,
      })
      .catch(() => alert("게시글 작성 중 문제가 발생하였습니다."));
  },
};

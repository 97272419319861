<template>
  <form>
    <textarea
      placeholder="댓글 내용을 입력해주세요"
      id="reply_input"
      class="required"
      cols="30"
      rows="10"
      name="comment"
      v-model="comment"
    ></textarea>
    <div class="flex flex-col flex-wrap">
      <div>
        <input
          class="required"
          type="text"
          placeholder="이름"
          size="25"
          name="name"
          v-model="name"
        />
        <input
          class="required"
          type="password"
          placeholder="비밀번호"
          size="25"
          name="password"
          v-model="password"
        />
      </div>
    </div>
    <div class="w-full relative flex justify-center items-center py-4">
      <div class="absolute left-0 top-4">
        <input type="checkbox" name="" id="secret_reply" v-model="secretYn" />
        <label for="secret_reply" class="hover:text-primary-blue">비밀글</label>
      </div>
      <button
        class="border px-4 py-2 bg-black text-white rounded-md hover:bg-primary-blue"
        @click="onSubmit"
      >
        댓글 등록
      </button>
    </div>
  </form>
</template>

<script>
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue-demi";

export default {
  props: {
    mode: { type: String, default: "댓글" },
  },
  setup(props, { emit }) {
    const secretYn = ref(false);
    const { handleSubmit, handleReset } = useForm({});
    const onInvalid = ({ values }) => {
      console.log("onInvalid", values);
    };
    const onSubmit = handleSubmit((values) => {
      const { name, password, comment } = values;
      console.log("성공", values, props.mode);
      emit("comment-submit", {
        mode: props.mode,
        name,
        password,
        content: comment,
        secret_yn: secretYn.value,
      }); // mode:댓글, 대댓글
      handleReset();
    }, onInvalid);
    const { value: comment, errorMessage: commentMessage } = useField(
      "comment",
      yup.string().required("댓글 내용을 입력해주세요.")
    );
    const { value: name, errorMessage: nameMessage } = useField(
      "name",
      yup.string().required("이름을 입력해주세요.")
    );
    const { value: password, errorMessage: passwordMessage } = useField(
      "password",
      yup.string().required("비밀번호를 입력해주세요.")
    );
    return {
      secretYn,
      comment,
      commentMessage,
      name,
      nameMessage,
      password,
      passwordMessage,
      onSubmit,
    };
  },
};
</script>

<style scoped>
#reply_input {
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
  vertical-align: middle;
  border-radius: 3px;
  padding: 5px;
  width: 100%;
  height: 120px;
}
input {
  height: 40px;
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
  vertical-align: middle;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
}
</style>

<template>
  <section class="main_section" id="company_section">
    <div>
      <p class="section_title">COMPANY</p>
      <span class="section_text"
        >축적된 노하우와 최고의 기술력을 통해 <br class="block lg:hidden" />일류
        제품을 생산하는 기업</span
      >
    </div>
    <div>
      <div class="flex flex-wrap justify-evenly w-full">
        <CompanyItem
          content="인사말"
          link="/board/About이음/인사말"
          style="visiblility: hidden; opacity: 0"
          v-animate
          animation="animate__animated animate__fadeInUp"
        >
          <template v-slot:icon>
            <fa-icon style="font-size: 2.6rem" :icon="['fas', 'building']" />
          </template>
        </CompanyItem>
        <CompanyItem
          content="연혁"
          link="/board/About이음/연혁"
          style="visiblility: hidden; opacity: 0"
          v-animate
          animation="animate__animated animate__fadeInDown"
        >
          <template v-slot:icon>
            <fa-icon style="font-size: 2.6rem" :icon="['fas', 'hourglass']" />
          </template>
        </CompanyItem>
        <CompanyItem
          content="CI소개"
          link="/board/About이음/CI소개"
          style="visiblility: hidden; opacity: 0"
          v-animate
          animation="animate__animated animate__fadeInUp"
        >
          <template v-slot:icon>
            <fa-icon style="font-size: 2.6rem" :icon="['fas', 'paper-plane']" />
          </template>
        </CompanyItem>
        <CompanyItem
          content="채용정보"
          link="/board/고객지원/채용정보"
          style="visiblility: hidden; opacity: 0"
          v-animate
          animation="animate__animated animate__fadeInDown"
        >
          <template v-slot:icon>
            <fa-icon style="font-size: 2.6rem" :icon="['fas', 'trophy']" />
          </template>
        </CompanyItem>
      </div>
    </div>
  </section>
</template>

<script>
import CompanyItem from "./HomeCompanyItem.vue";
export default {
  components: { CompanyItem },
};
</script>
<style scoped>
#company_section {
  background: url("../../assets/images/main/company_back.jpg") repeat-y;
  background-position: left center;
  padding: 100px 0 80px 0;
  max-width: 1980px;
  margin: 0 auto;
  width: 100%;
}
#company_section .section_title,
#company_section .section_text {
  color: white;
}
@media (max-width: 480px) {
  #company_section {
    padding: 50px 0;
  }
}
@media (max-width: 768px) {
  #company_section {
    margin-top: 0px;
  }
}
@media (max-width: 1100px) {
  #company_section {
    margin-top: 50px;
  }
}
</style>

<style></style>

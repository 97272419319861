<template>
  <div class="board_content_section">
    <picture>
      <source
        :srcset="require('../../../assets/images/board/0503.jpg')"
        media="(min-width: 800px)"
      />
      <img
        :srcset="require('../../../assets/images/board/0503m.jpg')"
        style="width: 100%"
      />
    </picture>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped></style>

<template>
  <div>
    <Outline
      :headerTitle="''"
      :title="methodData.outline.title"
      :content="methodData.outline.content"
    />
    <MethodCharacteristic
      :sectionTitle="methodData.characteristic.title"
      :content="methodData.characteristic.content"
    >
      <template v-slot:content>
        <div class="py-10">
          <img
            :src="require('../../../assets/images/board/0302_01.jpg')"
            alt=""
          />
        </div>
      </template>
    </MethodCharacteristic>
    <MethodAdvantage
      :title="'NB브레이스 장점'"
      :advantages="methodData.advantages"
    />
    <div class="mt-16 py-8 mx-auto" style="max-width: 1200px">
      <Outline
        :headerTitle="''"
        :title="methodData.performance.title"
        :content="methodData.performance.content"
      />
      <div class="my-12 lg:my-16 px-4 md:px-20 performance_wrapper">
        <h3 class="pt-6 font-bold text-white flex items-center justify-center">
          실험 개요
        </h3>
        <div class="md:py-6 px-2 project_box">
          <img
            class="w-full"
            :src="require('../../../assets/images/board/0302_01 (1).jpg')"
            alt=""
          />
        </div>
      </div>
      <div class="my-12 lg:my-16 px-4 md:px-20 performance_wrapper">
        <h3 class="pt-6 font-bold text-white flex items-center justify-center">
          실험 방법
        </h3>
        <div class="md:py-6 px-2 project_box">
          <img
            class="w-full"
            :src="require('../../../assets/images/board/0302_02.jpg')"
            alt=""
          />
        </div>
      </div>
      <div class="my-12 lg:my-16 px-4 md:px-20 performance_wrapper">
        <h3 class="pt-6 font-bold text-white flex items-center justify-center">
          실험결과 비교분석
        </h3>
        <div class="md:py-6 px-2 project_box">
          <picture>
            <source
              :srcset="require('../../../assets/images/board/0302_03.jpg')"
              media="(min-width: 800px)"
            />
            <img
              :src="require('../../../assets/images/board/0302_03m.jpg')"
              style="width: 100%"
            />
          </picture>

          <img
            :src="require('../../../assets/images/board/0302_04.jpg')"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div class="w-full mx-auto py-20">
      <Outline :headerTitle="''" :title="'NB브레이스 시공과정'"></Outline>
      <MethodFlow :flows="methodData.flows" />
    </div>
  </div>
</template>

<script>
import Outline from "../../Outline.vue";
import MethodCharacteristic from "./MethodCharacteristic.vue";
import MethodAdvantage from "./MethodAdvantage.vue";

import { method2 } from "@/constants/method";
import MethodFlow from "./MethodFlow.vue";

export default {
  setup() {
    const methodData = method2;
    return {
      methodData,
    };
  },
  components: { Outline, MethodCharacteristic, MethodAdvantage, MethodFlow },
};
</script>

<style scoped>
.performance_wrapper {
  background: transparent url("../../../assets/images/board/0302_b.jpg") left
    top no-repeat;
  overflow: hidden;
  margin-bottom: 80px;
}
.performance_wrapper h3 {
  font-size: 30px;
  line-height: 70px;
}
@media (max-width: 768px) {
  .performance_wrapper h3 {
    height: 50px;
    line-height: 50px;
  }
}
.sb_project .project_box {
  padding: 60px 30px 0;
  background: #fff;
  position: relative;
}
@media (max-width: 900px) {
  .sb_project {
    padding: 0 30px;
    margin-bottom: 40px;
  }
  .project_box {
    padding: 30px 0 0;
  }
}
@media (max-width: 1200px) {
  .sb_project {
    margin-bottom: 60px;
  }
}
</style>

<template>
  <li class="advantage_item">
    <div class="px-2 py-4">
      <div class="space-y-4 flex flex-col items-center">
        <h4 class="item_title" v-html="advantage.title"></h4>
        <div class="item_detail" v-for="item in advantage.contents" :key="item">
          <span>{{ item.description }}</span>
          <span
            ><strong>{{ item.strong }}</strong></span
          >
        </div>
        <div class="item_image">
          <img :src="advantage.imgSrc" alt="" title="" />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    advantage: Object,
  },
};
</script>

<style scoped>
.advantage_item {
  width: 250px;
  height: 500px;
  position: relative;
  margin: 10px;
  margin-bottom: 20px;
  background: #f7f7f7;
  border-top: 3px solid #0f52a1;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  transition: all 150ms linear;
  overflow: hidden;
}
.advantage_item:hover {
  background: #0f52a1;
  color: white;
}
.advantage_item:hover .item_detail,
.advantage_item:hover .item_title {
  color: white;
}
.advantage_item .item_title {
  font-size: 1.4em;
  font-weight: 700;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #b1aeae;
  width: 80%;
  margin: 0 auto;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantage_item .item_detail {
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
}
.advantage_item .item_detail span {
  display: block;
}
.advantage_item .item_image {
  width: 80%;
  /* padding-top: 1rem;
   */
}
.advantage_item .item_image img {
  width: 80%;
  margin: 0 auto;
}
</style>

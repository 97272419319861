<template>
  <div class="board_content_section px-2 md:px-4 v-full">
    <ul
      class="flex mb-4 mt-2 gap-0.5 m_hide"
      style="border-bottom: 1px solid #ddd"
    >
      <li
        v-for="category in allCategories"
        :key="category"
        @click.stop="onClickTab(category)"
        class="category w-24 lg:w-32 text-center"
        :style="category === currentTab ? 'background:black; color:white;' : ''"
      >
        <span class="w-full py-2 block cursor-pointer">{{ category }}</span>
      </li>
    </ul>
    <div class="m_show select_box" @click="onClickSelectBox">
      <p>{{ currentTab }}</p>
      <span
        class="btn_select_box"
        :class="mCategoryOptionOpen ? 'rotate' : ''"
      ></span>
      <div class="option_box" v-show="mCategoryOptionOpen">
        <div
          class="option_item"
          v-for="category in allCategories"
          :key="category"
          @click.stop="onClickTab(category)"
        >
          {{ category }}
        </div>
      </div>
    </div>
    <BoardList
      :notices="notices"
      :normal="normalBoards"
      :currentTab="currentTab"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

import { categories } from "@/constants/files";
import BoardList from "../../BoardList.vue";

import BoardApi from "../../../utils/api/Board";

export default {
  setup() {
    const boards = ref([]);
    const allCategories = categories;
    const route = useRoute();
    const router = useRouter();
    const currentTab = computed(() => route.query.category);
    const mCategoryOptionOpen = ref(false);

    const onClickSelectBox = () => {
      mCategoryOptionOpen.value = !mCategoryOptionOpen.value;
    };
    const onClickTab = (category) => {
      mCategoryOptionOpen.value = false;
      router.push(`/board/고객지원/자료실?category=${category}`);
    };

    onBeforeMount(async () => {
      // boards.value = boardList;
      const {
        query: { category },
      } = route;
      if (!category) {
        router.push(`/board/고객지원/자료실?category=전체`);
      }

      const data = await BoardApi.getList({
        menu: "files",
        type: "일반",
      });

      boards.value = data.list;
    });

    const notices = computed(() =>
      boards.value.filter(
        (v) =>
          (!route.query.category || route.query.category === "전체") &&
          v.notice_yn == "true"
      )
    );
    const normalBoards = computed(() =>
      boards.value.filter((v) => {
        if (!route.query.category || route.query.category === "전체") {
          if (v.notice_yn == "false") {
            return v;
          }
        } else if (v.division === route.query.category) {
          return v;
        }
      })
    );

    return {
      allCategories,
      currentTab,
      onClickTab,
      notices,
      normalBoards,
      mCategoryOptionOpen,
      onClickSelectBox,
      ...useSearchModal(),
    };
  },
  components: { BoardList },
};
const useSearchModal = () => {
  const searchModal = ref(false);

  const setModal = (bool) => (searchModal.value = bool);

  return { searchModal, setModal };
};
</script>

<style scoped>
li.category span {
  border: 1px solid #ddd;
}
li.category:hover span {
  text-decoration: none;
  background: #d2d6dc;
  border: 1px solid #d2d6dc;
  /* outline-bottom: 0px; */
}
.m_hide {
  display: flex;
}
.m_show {
  display: none;
}
.select_box {
  background: #fff;
  border: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
  text-indent: 15px;
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
  cursor: pointer;
}
.btn_select_box {
  background: url("../../../assets/images/icon/select.gif") no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 2.4rem;
  cursor: pointer;
}
.btn_select_box.rotate {
  transform: rotate(180deg);
}
.select_box .option_box {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid #333;
  background: #333;
}
.select_box .option_box .option_item {
  height: 35px;
  padding: 0.5rem 0;
  width: 100%;
  color: white;
}
@media (max-width: 768px) {
  .m_hide {
    display: none;
  }
  .m_show {
    display: flex;
  }
}
</style>

<template>
  <div>
    <GeneralOutline :title="outlineTitle" :content="outlineContent" />
    <BusinessCharacteristic
      :sectionTitle="'신축 구조 설계 특징'"
      :innerTitle="characteristicTitle"
      :content="characteristicContent"
    />
    <BusinessFlow title="신축구조 설계" :flows="flows" />
  </div>
</template>

<script>
import GeneralOutline from "../../Outline.vue";

import { business1 } from "@/constants/businessIntroduction";
import BusinessCharacteristic from "./BusinessCharacteristic.vue";
import BusinessFlow from "./BusinessFlow.vue";

export default {
  setup() {
    const outlineTitle = business1.outline.title;
    const outlineContent = business1.outline.content;

    const characteristicTitle = business1.characteristic.title;
    const characteristicContent = business1.characteristic.content;

    const flows = business1.flows;
    return {
      outlineTitle,
      outlineContent,
      characteristicTitle,
      characteristicContent,
      flows,
    };
  },
  components: { GeneralOutline, BusinessCharacteristic, BusinessFlow },
};
</script>

<style scoped></style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faHourglass,
  faPaperPlane,
  faTrophy,
  faEnvelopeOpen,
  faOutdent,
  faXmark,
  faSquareXmark,
  faAngleDown,
  faSearchPlus,
  faHammer,
  faExclamationTriangle,
  faEdit,
  faSearch,
  faReply,
  faList,
  faChevronUp,
  faChevronDown,
  faHome,
  faArrowUp,
  faLocation,
  faAngleUp,
  faFolderOpen,
  faEllipsisV,
  faTrashAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faBuilding,
  faHourglass,
  faPaperPlane,
  faTrophy,
  faEnvelopeOpen,
  faOutdent,
  faXmark,
  faSquareXmark,
  faAngleDown,
  faSearch,
  faSearchPlus,
  faHammer,
  faExclamationTriangle,
  faEdit,
  faReply,
  faList,
  faChevronUp,
  faChevronDown,
  faHome,
  faArrowUp,
  faLocation,
  faAngleUp,
  faFolderOpen,
  faEllipsisV,
  faTrashAlt,
  faTimes
);

// extend("require", (value) => {
//     if (value === undefined || value === null) return false;
//     if (typeof value === "string" && value.length === 0) return false;
//     return true;
// });

import { animate } from "./directives";

createApp(App)
  .use(store)
  .use(router)
  .directive("animate", animate)
  .component("fa-icon", FontAwesomeIcon)
  .mount("#app");

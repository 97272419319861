<template>
  <div class="board">
    <BoardBanner
      :title="currentMenu"
      :bg="
        menus.reduce((acc, cur, idx) => {
          if (cur.title === currentMenu) {
            acc = idx + 1;
          }
          return acc;
        }, 0)
      "
    />
    <BoardMenuWrapper
      :menu="currentMenu"
      :submenu="$route.params.subcategory"
      @click-menu="onClickMenu"
      @click-submenu="onClickSubmenu"
    >
      <template v-slot:menu>
        <div
          class="category_dropdown absolute top-14 left-0 w-full bg-white border"
          style="z-index: 50"
          :style="isMenuOpen ? `height:calc(48px * ${menus.length})` : ''"
          @mouseleave="isMenuOpen = false"
        >
          <div
            class="py-1 px-4 h-12 w-full flex items-center hover:bg-primary-blue hover:text-white transition-all duration-100"
            v-for="menu in menus"
            :key="menu"
            @click="
              () => {
                isMenuOpen = false;
                isSubmenuOpen = false;
                $router.push(menu.link);
              }
            "
          >
            {{ menu.title }}
          </div>
        </div>
      </template>
      <template v-slot:submenu>
        <div
          class="category_dropdown absolute top-14 left-0 w-full bg-white duration-200 transition-all"
          :style="
            isSubmenuOpen ? `height:calc(48px*${currentSubmenus.length})` : ''
          "
          style="z-index: 50"
          @mouseleave="isSubmenuOpen = false"
        >
          <div
            class="py-1 px-4 h-12 w-full flex items-center hover:bg-primary-blue hover:text-white transition-all duration-100"
            v-for="submenu in currentSubmenus"
            :key="submenu"
            @click="
              () => {
                isMenuOpen = false;
                isSubmenuOpen = false;
                $router.push({
                  name: 'BoardContent',
                  params: {
                    ...$route.params,
                    subcategory: submenu.title,
                  },
                });
              }
            "
          >
            {{ submenu.title }}
          </div>
        </div></template
      >
      <!-- menu, submenu dropdown slot -->
    </BoardMenuWrapper>
    <div class="board_title_wrapper">
      <div class="board_title">
        <h1 class="text-center font-medium text-4xl">
          {{ currentSubmenu }}
        </h1>
        <div class="h-0.5 w-52 bg-primary-blue mx-auto relative top-8"></div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <router-view />
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import BoardBanner from "@/components/Board/BoardBanner.vue";
import BoardMenuWrapper from "../components/Board/BoardMenuWrapper.vue";

export default {
  components: {
    BoardBanner,
    BoardMenuWrapper,
  },

  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      ...useMenu(),
    };
  },
};
const useMenu = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const menus = computed(() => store.getters["menus"]);

  const currentMenu = computed(() => route.params.category);
  const currentSubmenu = computed(() => {
    if (route.params.subcategory === "NB브레이스")
      return "횡력 저항 시스템인 철골 브레이스 공법";
    else if (route.params.subcategory === "C-TMD")
      return "C-TMD (Compact - Tuned Mass Damper)";
    else if (route.params.category === "주요실적") return "주요실적";
    return route.params.subcategory;
  });
  const currentSubmenus = computed(() => {
    const menu = menus.value.find((menu) => menu.title === currentMenu.value);
    // menu가 전체 menu 중 없으면 404
    if (!menu) {
      router.push("/404");
      return;
    }
    return menu?.submenus ?? [];
  });
  watch(currentMenu, (cur, prev) => {
    // menu 바뀔 시 새로고침
    if (cur !== prev) {
      console.log("diff menu");
      router.go(0);
    }
  });
  watch(
    currentSubmenu,
    (cur, prev) => {
      if (!cur) {
        router.push({
          name: "BoardContent",
          params: {
            ...route.params,
            subcategory: currentSubmenus.value[0].title,
          },
        });
      }

      if (cur && prev && cur !== prev) {
        console.log("diff submenu");
        router.go(0);
      }
    },
    {
      // immediate: true,
    }
  );

  const isMenuOpen = ref(false);
  const isSubmenuOpen = ref(false);
  const onClickMenu = () => {
    isSubmenuOpen.value = false;
    isMenuOpen.value = !isMenuOpen.value;
  };
  const onClickSubmenu = () => {
    isMenuOpen.value = false;
    isSubmenuOpen.value = !isSubmenuOpen.value;
  };

  return {
    menus,
    currentMenu,
    currentSubmenu,
    currentSubmenus,
    isMenuOpen,
    isSubmenuOpen,
    onClickMenu,
    onClickSubmenu,
  };
};
</script>

<style scoped>
.board_title_wrapper {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.6em;
  font-family: "NanumSquare", sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
}
.board_title_wrapper .board_title {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 30px 0;
  margin: 30px auto 80px auto;
}
.dropdown {
  animation: dropdown 200ms linear;
  animation-fill-mode: forwards;
}
@keyframes dropwdown {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(100);
  }
}
</style>
